import { Button, Card, CardContent, CircularProgress, IconButton, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import { ArrowBack, Delete } from '@material-ui/icons'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useDb } from '../../contexts/DatabaseContext'
import { year } from '../shared/consants'

export default function AnglerDetails() {

  const { uid } = useParams()
  const { getAnglerDetails, deleteResult } = useDb()
  const [anglerDetails, setAnglerDetails] = useState([])
  const [loading, setLoading] = useState(true)
  const [updated, setUpdated] = useState(0)
  const history = useHistory()

  useEffect(async () => {
    const aD = await getAnglerDetails(uid, '2023')
    console.log(aD)
    setAnglerDetails( await aD)
    setLoading(false)
  }, [uid, updated])

  const handleDelete = (ref) => {
    deleteResult(ref.id)
    .then(() => {
      setUpdated(updated + 1)
    })
  }

  return (
    
    <div>
      {
      loading 
      ?
      <CircularProgress />
      :
      <div>
        <h1>
          Angler Details: {`${anglerDetails[0].firstname} ${anglerDetails[0].pmType} ${anglerDetails[0].pmNumber}`}
        </h1>
        <Button startIcon={<ArrowBack />} variant='contained' color='primary' onClick={() => history.goBack()}>Back</Button>
        <br />
        <br />
        <Card elevation={3} style={{borderRadius: 10}}>
          <CardContent>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>Competition</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>BMT</TableCell>
                  <TableCell>Winnings</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  anglerDetails.map((aD, index) => (
                    <TableRow key={index}>
                      <TableCell>{index +1}</TableCell>
                      <TableCell>{aD.name}</TableCell>
                      <TableCell>{aD.date ? moment(aD.date.toDate()).format("DD/MM/YYYY") : null}</TableCell>
                      <TableCell>{parseFloat(aD.bmt).toFixed(2)}</TableCell>
                      <TableCell>{parseFloat(aD.winnings).toFixed(2)}</TableCell>
                      <TableCell><IconButton onClick={() => handleDelete(aD.ref)}><Delete /></IconButton></TableCell>
                    </TableRow>
                  ))
                }
              </TableBody>
            </Table>
          </CardContent>
        </Card>
      </div>
      }
    </div>
  )
}
