import React, { useContext, useState, useEffect } from 'react';
import { auth, db } from '../firebase';

const AuthContext = React.createContext();

export function useAuth() {
    return useContext(AuthContext)
}

export default function AuthProvider({ children }) {
    const [currentUser, setCurrentUser] = useState()
    const [loading, setLoading] = useState(true)

    async function logOut() {
        return await auth.signOut().then(() => {
            window.location.reload()
        })
    }

    function logIn(email, password) {
        return auth.signInWithEmailAndPassword(email, password)
    }

    function forgotPassword(email) {
        return auth.sendPasswordResetEmail(email)
    }

    
    function createUserRecord(uid, data) {
        db.collection('users').doc(uid).set({
            uid: uid,
            ...data
        })
    }

    async function signUp(email, password, data) {
        return auth.createUserWithEmailAndPassword(email, password).then((userCred) => {
            createUserRecord(userCred.user.uid, data) 
        })
    }

    async function getUserRecord(uid) {
        const user = await db.collection('users').doc(uid).get()
        .then(doc => ({
            ...doc.data(),
            ref: doc.ref
        }))
        return user
    }

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(async user => {
            if(user) {
                const userDoc = await getUserRecord(user.uid)
                setCurrentUser({ ...userDoc, uid: user.uid })
            }
            setLoading(false)
        })
        return unsubscribe
    }, [])

    const value = {
        currentUser,
        signUp,
        logOut,
        logIn,
        forgotPassword
    }
    return (
        <AuthContext.Provider value={value}>
            { !loading && children }
        </AuthContext.Provider>
    )
}
