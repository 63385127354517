import { Button, Card, CardActions, CardContent, CircularProgress, TextField } from '@material-ui/core'
import { ArrowBack, Save } from '@material-ui/icons'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import { useDb } from '../../../contexts/DatabaseContext'
import { elevation } from '../../shared/consants'

export default function EditResults() {

  const history = useHistory()
  const { compId, resId } = useParams()
  const { getResult } = useDb()
  const [result, setResult] = useState({})
  const [loading, setLoading] = useState(true)

  console.log(resId)

  useEffect(async () => {
    const res = await getResult(resId)
    setResult(res && res)
    setLoading(false)
  }, [resId])

  console.log(result)

  const handleChange = (target) => {
    setResult({
      ...result,
      [target.name]: parseFloat(target.value)
    })
  }

  const handleSave = (e) => {
    e.preventDefault()
    result.ref.update({
      ...result
    }).then(() => history.goBack())
  }

  return (
    <>
      {
        loading
        ?
        <CircularProgress />
        :
        <div>
          <h1>Edit Result: {result.display_name}</h1>
          <Button startIcon={<ArrowBack />} variant='contained' color='primary' onClick={() => history.goBack()}>Back</Button>
          <br /><br />
          <Card elevation={elevation} style={{borderRadius: 10}}>
            <form onSubmit={(e) => handleSave(e)}>
              <CardContent>
                <TextField
                  label='pos'
                  type='number'
                  value={result.pos}
                  variant="outlined" 
                  name='pos'
                  fullWidth 
                  required
                  onChange={(e) => handleChange(e.target)}
                />
                <br /><br />
                <TextField
                  label='bmt'
                  type='number'
                  value={result.bmt }
                  variant="outlined" 
                  name='bmt'
                  fullWidth 
                  required
                  onChange={(e) => handleChange(e.target)}
                />
                <br /><br />
                <TextField
                  label='Total Fish'
                  type='number'
                  value={result.totalFish}
                  variant="outlined" 
                  name='totalFish'
                  fullWidth 
                  required
                  onChange={(e) => handleChange(e.target)}
                />
                <br /><br />
                <TextField
                  label='Total Weight'
                  type='number'
                  value={result.totalWeight }
                  variant="outlined" 
                  name='totalWeight'
                  fullWidth 
                  required
                  onChange={(e) => handleChange(e.target)}
                />
                <br /><br />
                <TextField
                  label='Score'
                  type='number'
                  value={((result.totalFish * 10) + (result.totalWeight * 10)).toFixed(2)}
                  variant="outlined" 
                  name='score'
                  fullWidth 
                  required
                  onChange={(e) => handleChange(e.target)}
                />
                <br /><br />
                <TextField
                  label='Winnings'
                  type='number'
                  value={result.winnings}
                  variant="outlined" 
                  name='winnings'
                  fullWidth 
                  required
                  onChange={(e) => handleChange(e.target)}
                />
                <br /><br />
              </CardContent>
              <CardActions className='pm-right'>
                <Button
                  variant='contained'
                  color='primary'
                  startIcon={<Save />}
                  type='submit'
                >Save</Button>
              </CardActions>
            </form>
          </Card>
        </div>
      }
    </>
  )
}
