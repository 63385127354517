import { AppBar, Badge, Button, CircularProgress, Collapse, CssBaseline, Divider, Drawer, Hidden, IconButton, List, ListItem, ListItemIcon, ListItemText, makeStyles, Menu, Toolbar, Typography } from '@material-ui/core';
import { ArchiveOutlined, BarChart, Close, CloudDownload, Event, ExpandLess, ExpandMore, FormatListNumbered, FormatListNumberedRounded, Home, Image, ListAlt, ListAltRounded, Menu as MenuIcon, MonetizationOn, Notifications, NotificationsSharp, PageviewRounded, People } from '@material-ui/icons';
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom'
import { useAuth } from '../../contexts/AuthContext';
import logo from '../../img/pmIcon.png'
import AdminRoutes from '../../routes/AdminRoutes';
import { version } from '../shared/consants';
import CreateTicket from '../shared/CreateTicket';
import FloatingActionButton from '../shared/FloatingActionButton';
import { useDb } from '../../contexts/DatabaseContext';
import AllNotifications from '../notifications/NotificationPopup';
import NotificationPopup from '../notifications/NotificationPopup';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
    drawer: {
        [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0,
        },
    },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
    background: theme.palette.primary.main,
    borderBottom: '1px solid #e0e0e0'
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    background: theme.palette.primary.main,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
    icons: {
            color: '#fff',
            marginLeft: theme.spacing(2),
    },
    menuText: {
        color: theme.palette.mainMenuText,
        textDecoration: 'none',
    },
    subMenuItem: {
        marginLeft: 20
    },
}));

export default function AdminPage(props) {
    const { window } = props;
    const history = useHistory()
    const { currentUser } = useAuth()
    const classes = useStyles()
    const [rankingOpen, setRankingOpen] = useState(false)
    const [mobileOpen, setMobileOpen] = useState(false);
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };
    const { logOut } = useAuth()

    const handleLogout = async () => {
        logOut()
        history.push('/')
    }

    const { GetHostNotiCount } = useDb()
    const [loading, setLoading] = useState(true)
    const [notiCount, setNotiCount] = useState(true)

    useEffect(async () => {
        const len = await GetHostNotiCount().then(d => {return d})
        console.log(len)
        setNotiCount(len)
        setLoading(false)
    }, [])

    const handleNotiClick = () => {

    }

    console.log(currentUser)

    const drawer = (
        <div>
            <img src={logo} width={45} style={{ position: 'absolute', top: 10, left: 10, marginBottom: 10}}/>
            <center><h4 style={{color: "#fff"}}>V {version}</h4></center>
            {/* <div className={classes.toolbar} /> */}
            <Divider />
            {
                currentUser.role === "Admin"
                ?
                <List>
                <Link to="/competitions/1">
                    <ListItem>
                        <ListItemIcon className={classes.icons}><Event /></ListItemIcon>
                        <ListItemText className={classes.menuText}>Competitions</ListItemText>
                    </ListItem>
                </Link>
                <Link to="/competitions/1/archived">
                    <ListItem>
                        <ListItemIcon className={classes.icons}><ArchiveOutlined /></ListItemIcon>
                        <ListItemText className={classes.menuText}>Archived Comps</ListItemText>
                    </ListItem>
                </Link>
                <Link to="/export/rankings">
                    <ListItem>
                        <ListItemIcon className={classes.icons}><CloudDownload /></ListItemIcon>
                        <ListItemText className={classes.menuText}>Export Rankings</ListItemText>
                    </ListItem>
                </Link>
                <ListItem button onClick={() => setRankingOpen(!rankingOpen)}>
                <ListItemIcon className={classes.icons}><BarChart /></ListItemIcon>
                <ListItemText className={classes.menuText} primary="Rankings" />
                    {rankingOpen ? <ExpandLess className={classes.icons} /> : <ExpandMore className={classes.icons} />}
                </ListItem>
                <Collapse in={rankingOpen} timeout="auto" unmountOnExit>
                    <Link to="/rankings">
                        <ListItem className={classes.subMenuItem}>
                            <ListItemIcon className={classes.icons}><BarChart /></ListItemIcon>
                            <ListItemText className={classes.menuText}>Rankings 2024</ListItemText>
                        </ListItem>
                    </Link>
                    <Link to="/rankings-2023">
                        <ListItem className={classes.subMenuItem}>
                            <ListItemIcon className={classes.icons}><BarChart /></ListItemIcon>
                            <ListItemText className={classes.menuText}>Rankings 2023</ListItemText>
                        </ListItem>
                    </Link>
                    <Link to="/rankings-2022">
                        <ListItem className={classes.subMenuItem}>
                            <ListItemIcon className={classes.icons}><BarChart /></ListItemIcon>
                            <ListItemText className={classes.menuText}>Rankings 2022</ListItemText>
                        </ListItem>
                    </Link>                    
                </Collapse>
                <Link to="/anglers/1">
                    <ListItem>
                        <ListItemIcon className={classes.icons}><People /></ListItemIcon>
                        <ListItemText className={classes.menuText}>Anglers</ListItemText>
                    </ListItem>
                </Link>
                <Link to="/hosts/">
                    <ListItem>
                        <ListItemIcon className={classes.icons}><People /></ListItemIcon>
                        <ListItemText className={classes.menuText}>Hosts</ListItemText>
                    </ListItem>
                </Link>
                <Link to="/pm_numbers">
                    <ListItem>
                        <ListItemIcon className={classes.icons}><FormatListNumberedRounded /></ListItemIcon>
                        <ListItemText className={classes.menuText}>PM Numbers</ListItemText>
                    </ListItem>
                </Link>
                <Link to="/sponsors">
                    <ListItem>
                        <ListItemIcon className={classes.icons}><Image /></ListItemIcon>
                        <ListItemText className={classes.menuText}>Sponsors</ListItemText>
                    </ListItem>
                </Link>
                {/* <Link to="/notifications">
                    <ListItem>
                        <ListItemIcon className={classes.icons}><Notifications /></ListItemIcon>
                        <ListItemText className={classes.menuText}>Notifications</ListItemText>
                    </ListItem>
                </Link> */}
                <Link to="/tickets">
                    <ListItem>
                        <ListItemIcon className={classes.icons}><PageviewRounded /></ListItemIcon>
                        <ListItemText className={classes.menuText}>Tickets</ListItemText>
                    </ListItem>
                </Link>
                <Link to="/logs">
                    <ListItem>
                        <ListItemIcon className={classes.icons}><ListAltRounded /></ListItemIcon>
                        <ListItemText className={classes.menuText}>Logs</ListItemText>
                    </ListItem>
                </Link>
                <ListItem>
                    <Button startIcon={<Close />} onClick={handleLogout} variant='contained' color='secondary' >Logout</Button>
                </ListItem>
                
            </List>
            :
            <List>
                <Link to="/competitions/1">
                    <ListItem>
                        <ListItemIcon className={classes.icons}><Event /></ListItemIcon>
                        <ListItemText className={classes.menuText}>Competitions</ListItemText>
                    </ListItem>
                </Link>
                <Link to="/competitions/1/archived">
                    <ListItem>
                        <ListItemIcon className={classes.icons}><Event /></ListItemIcon>
                        <ListItemText className={classes.menuText}>Archived Comps</ListItemText>
                    </ListItem>
                </Link>
                <Link to="/notifications">
                    <ListItem>
                        <ListItemIcon className={classes.icons}><Notifications /></ListItemIcon>
                        <ListItemText className={classes.menuText}>Notifications</ListItemText>
                    </ListItem>
                </Link>
                <Link to="/rankings">
                    <ListItem>
                        <ListItemIcon className={classes.icons}><BarChart /></ListItemIcon>
                        <ListItemText className={classes.menuText}>Rankings</ListItemText>
                    </ListItem>
                </Link>
                <Link to="/rankings-2023">
                    <ListItem>
                        <ListItemIcon className={classes.icons}><BarChart /></ListItemIcon>
                        <ListItemText className={classes.menuText}>Rankings 2023</ListItemText>
                    </ListItem>
                </Link>
                <Link to="/rankings-2022">
                    <ListItem>
                        <ListItemIcon className={classes.icons}><BarChart /></ListItemIcon>
                        <ListItemText className={classes.menuText}>Rankings 2022</ListItemText>
                    </ListItem>
                </Link>
                <ListItem>
                    <Button startIcon={<Close />} onClick={handleLogout} variant='contained' color='secondary' >Logout</Button>
                </ListItem>
            </List>
            }
        </div>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    const [open, setOpen] = useState(false)

    const handleClose = () => {
        setOpen(false)
    } 

    const handleOnClick = () => {
        setOpen(true)
        console.log(history.location.pathname)
    }

    const [notifAnchorEl, setNotifAnchorEl] = useState(null);

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar elevation={0} position="fixed" className={classes.appBar}>
                <Toolbar >
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        className={classes.menuButton}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Menu
                        anchorEl={notifAnchorEl}
                        open={Boolean(notifAnchorEl)}
                        keepMounted
                        onClose={() => setNotifAnchorEl(null)}
                    >
                        <NotificationPopup/>
                    </Menu>
                    {
                        loading
                        ?
                        <CircularProgress />
                        :
                        <Badge badgeContent={notiCount } color='secondary' style={{marginLeft: '95%'}}>
                            <IconButton size='small' onClick={(e) => setNotifAnchorEl(e.currentTarget)}>
                                <NotificationsSharp />
                            </IconButton>
                        </Badge>
                    }
                </Toolbar>
            </AppBar>
            <nav className={classes.drawer} aria-label="mailbox folders">
            <Hidden smUp implementation="css">
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                >
                    {drawer}
                </Drawer>
            </Hidden>
            <Hidden xsDown implementation="css">
                <Drawer
                    classes={{
                    paper: classes.drawerPaper,
                    }}
                    variant="permanent"
                    open
                >
                    {drawer}
                </Drawer>
            </Hidden>
            </nav>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <AdminRoutes />
                <FloatingActionButton onClick={handleOnClick} />
                <CreateTicket open={open} handleClose={handleClose} path={history.location.pathname} />
            </main>
        </div>
    )
}
