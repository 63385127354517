import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { useAuth } from '../../../contexts/AuthContext'
import { useDb } from '../../../contexts/DatabaseContext'
import { selectFieldProps } from '../../shared/consants'

export default function LogResults({open, handleClose, compId }) {
  
  const { currentUser } = useAuth()
  const [state, setState] = useState({
    marshall: currentUser.uid,
  })
  const { GetBookingsDropdown, logResults } = useDb()
  const bookings = GetBookingsDropdown(compId)

  console.log(bookings)

  const bookingsOptions = bookings && bookings.map(bk => ({
    label: `${bk.display_name} - ${bk.email}`,
    value: {...bk}
  }))

  const handleSelect = (value) => {
    setState({
      ...state,
      ...value
    })
  }

  const handleSubmit = async () => {
    setState({
      ...state,
      timestamp: new Date()
    })
    console.log(state)
    await logResults(state)
    handleClose()
  }

  console.log(state)

  return (
    <Dialog open={open} onClose={() => {
        handleClose()
        setState(null)
      }} maxWidth="md" fullWidth style={{padding: '10px'}} >
      <DialogTitle>Log Results</DialogTitle>
      <DialogContent>
        {/* select user  */}
        <Select
          options={bookingsOptions}
          {...selectFieldProps}
          fullWidth
          variant="outlined" 
          onChange={(val) => handleSelect(val.value)}
        />
        <br />
        <TextField
          label='pos'
          required 
          type='number'
          variant="outlined" 
          fullWidth 
          onChange={(e) => {
            setState({
              ...state, 
              pos: parseInt(e.target.value)
            })
          }}
        />
        <br /><br />
        <TextField
          label='BMT'
          required 
          type='number'
          variant="outlined" 
          fullWidth 
          onChange={(e) => {
            setState({
              ...state, 
              bmt: parseFloat(e.target.value)
            })
          }}
        />
        <br /><br />
        <TextField
          label='Standard Fish'
          required 
          type='number'
          variant="outlined" 
          fullWidth 
          onChange={(e) => {
            setState({
              ...state,
              standardQty: parseInt(e.target.value)
            })
          }}
        />
        <br /><br />
        <TextField
          label='Standard weight'
          required 
          type='number'
          variant="outlined" 
          fullWidth 
          onChange={(e) => {
            setState({
              ...state,
              standardWeight: parseFloat(e.target.value)
            })
          }}
        />
        <br /><br />
        <TextField
          label='yellow Fish'
          required 
          type='number'
          variant="outlined" 
          fullWidth 
          onChange={(e) => {
            setState({
              ...state,
              yellowQty: parseInt(e.target.value)
            })
          }}
        />
        <br /><br />
        <TextField
          label='yellow weight'
          required 
          type='number'
          variant="outlined" 
          fullWidth 
          onChange={(e) => {
            setState({
              ...state,
              yellowWeight: parseFloat(e.target.value)
            })
          }}
        />
        <br /><br />
        <TextField
          label='winnings'
          required
          type='number'
          variant="outlined" 
          fullWidth 
          onChange={(e) => {
            setState({
              ...state,
              winnings: parseFloat(e.target.value)
            })
          }}
        />
      </DialogContent>
      <DialogActions>
          <Button variant='contained' color='primary' onClick={handleSubmit} >Submit</Button>
      </DialogActions>
    </Dialog>
  )
}
