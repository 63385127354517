import { Button, Card, CardActions, CardContent, CircularProgress, Grid, IconButton, LinearProgress, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip } from '@material-ui/core'
import { Archive, ArchiveOutlined, BarChartOutlined, DateRangeRounded, Filter, Filter1, FilterListOutlined, HighlightOffOutlined, ListAltOutlined, PersonOutline, Search } from '@material-ui/icons'
import { Pagination } from '@material-ui/lab'
import moment from 'moment'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import Select from 'react-select'
import { useAuth } from '../../contexts/AuthContext'
import { useDb } from '../../contexts/DatabaseContext'
import { elevation, selectItemsPerPage } from '../shared/consants'


export default function CompetitionList() {
  
  const { pager } = useParams()
  const { GetComps, GetHostComps } = useDb()
  const { currentUser } = useAuth()
  const startComps = currentUser.role === 'Host' ? GetHostComps(currentUser.uid) :  GetComps()
  const [compList, setCompList] = useState(startComps && startComps)
  const history = useHistory()
  console.log(startComps)
  //loading
  const [loading, setLoading] = useState(true)
  
  const [search, setSearch] = useState('')
  
  const [comps, setComps] = useState(compList && compList)
  // Pagination
  const [itemsPerPage, setItemsPerPage] = useState(7)
  const [page, setPage] = useState(parseInt(pager) && parseInt(pager)) 
  
  const [noOfPages] = useState(
    Math.ceil((compList.length == 0 ? startComps : search ? comps : compList).length / itemsPerPage)  
  )

  console.log(page, pager)

  const handleArchive = (comp) => {
    comp.ref.update({
      archived: true
    })
  }
  
  useEffect(() => {
    let tempArray = [];
    (compList.length == 0 ? startComps : compList).map(c => {
      if(
        c.name.toLowerCase().includes(search.toLowerCase()) ||
        c.availablePegs.toString().toLowerCase().includes(search.toLowerCase()) ||
        c.pegs.toString().toLowerCase().includes(search.toLowerCase()) ||
        c.type.toString().toLowerCase().includes(search.toLowerCase()) ||
        moment(c.date.toDate()).format('DD/MM/YY').includes(search.toLowerCase()) ||
        c.venue.toLowerCase().includes(search.toLowerCase())
      ) {
        setPage(1)
        history.push('1')
        tempArray.push(c)
      }
    })
    if (search == '') {
      setComps(startComps)
      setCompList(startComps)
    } else {
      setComps(tempArray)
      setCompList(tempArray)
    }
    setLoading(false)
  }, [search]) 

  console.log(compList)
  console.log(comps)

  return (
    <div>
      {
        loading 
        ? 
        <CircularProgress />
        :
        <Card elevation={elevation} style={{borderRadius: 10}}>
        <CardContent>
          <Grid container spacing={5}>
            <Grid item xs={12} sm={9}>
              <TextField
                label='Search'
                type='text'
                variant="outlined" 
                fullWidth 
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={3} >
              Items Per Page
              <Select value={itemsPerPage} options={selectItemsPerPage} onChange={(val) => {
                setItemsPerPage(val.value)
                setPage(1)
              }}/>
            </Grid>
          </Grid>
          <Table>
            <TableHead>
              <TableCell>Name</TableCell>
              <TableCell>Venue</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Available Pegs</TableCell>
              <TableCell>Total Bookings</TableCell>
              {/* <TableCell>Teams</TableCell> */}
              <TableCell>Bookings</TableCell>
              <TableCell>Results</TableCell>
              <TableCell>View/Edit</TableCell>
              {
                currentUser.admin
                ?
                <TableCell>Archive</TableCell>
                :
                null
              }
            </TableHead>
            <TableBody>
              {
                (compList.length == 0 ? startComps : search ? comps : compList)
                .slice((page -1) * itemsPerPage, page * itemsPerPage)
                .map(c => {
                  return (
                    <TableRow key={c.id}>
                        <TableCell>{c.name}</TableCell>
                        <TableCell>{c.venue}</TableCell>
                        <TableCell>{moment(c.date.toDate()).format('DD/MM/YY')}</TableCell>
                        <TableCell>{c.availablePegs}</TableCell>
                        <TableCell>{c.totalBookings}</TableCell>
                        {/* {
                          c.type === 'Individual'
                          ?
                          <TableCell> </TableCell>
                          :
                          <TableCell>
                            <Tooltip arrow title='This is not yet Availible'>
                              <IconButton
                                onClick={() => history.push(`competitions/${c.id}/teams`)}
                              ><PersonOutline /></IconButton>
                            </Tooltip>
                          </TableCell>
                        } */}
                        <TableCell>
                          <IconButton
                            onClick={() => history.push(`${c.id}/${c.type}/bookings`)}
                          ><ListAltOutlined /></IconButton>  
                        </TableCell>
                        <TableCell>
                          <IconButton
                            onClick={() => history.push(`${c.id}/results`)}
                          ><BarChartOutlined /></IconButton>
                        </TableCell>
                        <TableCell>
                          <IconButton
                            onClick={() => history.push(`${c.id}/details`)}
                          ><Search /></IconButton>
                        </TableCell>
                        {
                          currentUser.admin
                          ?
                          <TableCell><IconButton
                            onClick={() => {handleArchive(c)}}
                          ><ArchiveOutlined /></IconButton></TableCell>
                          :
                          null
                        }
                    </TableRow>
                  )
                })
              }
            </TableBody>
          </Table>
        </CardContent>
        <CardActions>
          <Pagination
            count={Math.ceil((compList.length == 0 ? startComps : search ? comps : compList).length / itemsPerPage)}
            page={page}
            onChange={(e, val) => {
              history.push(`${val}`)
              setPage(val)
            }}
            defaultPage={page}
            color="primary"
            size="large"
            showFirstButton
            showLastButton
          />  
        </CardActions>
      </Card>
      }
    </div>
  )
}
