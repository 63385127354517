import { Button, ButtonGroup, Card, CardContent, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@material-ui/core'
import { Delete, Edit, Search } from '@material-ui/icons'
import React from 'react'
import { Link } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { useDb } from '../../../contexts/DatabaseContext'
import { elevation } from '../../shared/consants'

export default function TeamsList() {

  const { compId } = useParams()
  const { GetTeams } = useDb()
  const teams = GetTeams(compId)
  console.log(compId)
  console.log(teams)

  const handleVerification = (i) => {
    const team = teams[i]
    team.ref.update({
      verification: !team.verification
    })
  }

  return (
    <div>
      <Card elevation={elevation} style={{borderRadius: 10}}>
        <CardContent>
          <Table>
            <TableHead>
              <TableCell>Team Name</TableCell>
              <TableCell>Members</TableCell>
              <TableCell>Verification</TableCell>
              <TableCell>Actions</TableCell>
            </TableHead>
            <TableBody>
              {
                teams.map((t, i) => {
                  return(
                    <TableRow>
                      <TableCell>{t.teamName}</TableCell>
                      <TableCell>{t.members.length}</TableCell>
                      <TableCell>
                        {
                          t.verification
                          ?
                          <Button variant='outlined'>verified</Button>
                          :
                          <Button variant='contained' color='primary'
                            onClick={() => handleVerification(i)}
                          >verify</Button>
                        }
                      </TableCell>
                      <TableCell>
                        <ButtonGroup>
                          <Tooltip title='Edit' arrow>
                            <IconButton><Edit /></IconButton>
                          </Tooltip>
                          {/* <Tooltip title='View Members'>
                            <Link to={`/competitions/${compId}/team/${t.teamId}/view`}>
                              <IconButton><Search /></IconButton>
                            </Link>
                          </Tooltip> */}
                          <Tooltip title="Delete" arrow>
                            <IconButton><Delete /></IconButton>
                          </Tooltip>
                        </ButtonGroup>
                      </TableCell>
                    </TableRow>
                  )
                })
              }
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </div>
  )
}
