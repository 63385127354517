import { Button, Card, CardActions, CardContent, CircularProgress, IconButton, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip } from '@material-ui/core'
import { Check, Delete, Edit, PersonPinCircleTwoTone } from '@material-ui/icons'
import { Pagination } from '@material-ui/lab'
import moment from 'moment'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { CSVLink } from 'react-csv'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { useAuth } from '../../../contexts/AuthContext'
import { useDb } from '../../../contexts/DatabaseContext'
import { elevation } from '../../shared/consants'
import Allocation from './allocations/Allocation'

export default function BookingsList() {

  const { compId, compType } = useParams()
  const { currentUser } = useAuth()
  const { GetBookings, deleteBooking, confirmBooking, confirmAllBookings, getComp, getUser } = useDb()
  const bookings = GetBookings(compId)
  const [bookingsList, setBookingsList] = useState(bookings && bookings)
  const [dataCSV, setDataCSV] = useState([])
  useEffect(async () => {
    const data = await bookings && bookings.map(async b => {
      const user = await getUser(b.uid)
      return {
        'pmType': user.pmType,
        'pmNumber': user.pmNumber,
        'name': b.display_name,
        'email': b.email,
        'phone': b.phone_number ? b.phone_number : b.phoneNumber,
      }
    })
    setDataCSV(await Promise.all(data))
    setLoading(false)
  }, [bookings])

  console.log(dataCSV)
  
  const [search, setSearch] = useState('')

  const [bkId, setBkId] = useState()
  const [uid, setUid] = useState()
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)

  // Pagination
  const itemsPerPage = 6
  const [page, setPage] = useState(1)
  const [noOfPages] = useState(
    Math.ceil((search ? bookingsList : bookings).length / itemsPerPage)
  )

  useEffect(() => {
    let tempArray = []
    bookings.map(b => {
      if(
        b.display_name.toLowerCase().includes(search.toLowerCase()) ||
        b.email.toString().toLowerCase().includes(search.toLowerCase()) ||
        b.phone_number.toString().toLowerCase().includes(search.toLowerCase()) ||
        b.pmNumber.toString().toLowerCase().includes(search.toLowerCase()) ||
        b.pmType.toString().toLowerCase().includes(search.toLowerCase()) ||
        moment(b.timestamp.toDate()).format('DD/MM/YY h:mm').includes(search.toLowerCase())
      ) {
        setPage(1)
        tempArray.push(b)
      }
    })
    setBookingsList(tempArray)
  }, [search])

  const handleClose = () => {
    setOpen(false)
    setBkId('')
  }

  let unconfirmed = 0
  for (let i = 0; i < bookings.length; i++) {
    const bk = bookings[i];
    if (!bk.confirmed) {
      unconfirmed++
    }
  }

  const handleOpen = (bkId, uid) => {
    setUid(uid)
    setBkId(bkId)
    setOpen(true)
  }

  const handleConfirm = async (bkId, uid) => {
    await confirmBooking(bkId, compId, uid, true)
  }
  
  const handleUnconfirm = async (bkId, uid) => {
    await confirmBooking(bkId, compId, uid, false)
  }

  const handleDelete = async (bkId, uid) => {
    setLoading(true)
    await deleteBooking(bkId, compId, uid)
    setLoading(false)
  }

  return (
    <div>
      {
        loading
        ?
        <CircularProgress />
        :
        <Card elevation={elevation} style={{borderRadius: 10}}>
          <CardContent>
            <TextField
              label='Search'
              type='text'
              variant="outlined" 
              fullWidth 
              onChange={(e) => setSearch(e.target.value)}
            />
            <Table>
              <TableHead>
                <TableCell>Booked</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Pm Number</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Phone</TableCell>
                {
                  compType === 'Teams' || compType === 'Pairs'
                  ?
                  <TableCell>Team Name</TableCell>
                  :
                  null
                }
                <TableCell>Pool</TableCell>
                <TableCell>Zone</TableCell>
                <TableCell>Peg</TableCell>
                <TableCell>Allocate</TableCell>
                <TableCell>Confirmation</TableCell>
                {
                  currentUser.admin 
                  ?
                  <TableCell>Action</TableCell>
                  :
                  null
                }
              </TableHead>
              <TableBody>
                {
                  (search ? bookingsList : bookings)
                  .slice((page -1) * itemsPerPage, page * itemsPerPage)
                  .map(b => {
                    return (
                      <TableRow key={b.uid}>
                          <TableCell>{moment(b.timestamp.toDate()).format('DD/MM/YY h:mm')}</TableCell>
                          <TableCell>{b.display_name}</TableCell>
                          <TableCell>{`${b.pmType} ${b.pmNumber}`}</TableCell>
                          <TableCell>{b.email}</TableCell>
                          <TableCell>{b.phone_number}</TableCell>
                          {
                            compType === 'Teams' || compType === 'Pairs'
                            ?
                            <TableCell>{b.teamName}</TableCell>
                            :
                            null
                          }
                          <TableCell>{`R${b.entryFee}`}</TableCell>
                          <TableCell>{b.zone ? b.zone : 'Not Yet Allocated'}</TableCell>
                          <TableCell>{b.peg ? b.peg : 'Not Yet Allocated'}</TableCell>
                          <TableCell>
                            {
                              b.confirmed
                              ?
                              <IconButton
                                onClick={() => handleOpen(b.id, b.uid)}
                              >
                                <Edit />
                              </IconButton>
                              :
                              <Tooltip arrow title='Confirm booking before allocating pegs'>
                                <IconButton>
                                  <Edit />
                                </IconButton>
                              </Tooltip>
                            }
                          </TableCell>
                          <TableCell>
                            {
                              !b.confirmed 
                              ?
                              <Button
                                onClick={() => handleConfirm(b.id, b.uid)}
                                variant='contained'
                                color='primary'
                                startIcon={<Check />}
                              >Confirm</Button>
                              :
                              <Button
                                onClick={() => handleUnconfirm(b.id, b.uid)}
                                variant='outlined'
                                startIcon={<Check />}
                              >Confirmed</Button>
                            }
                          </TableCell>
                          {
                            currentUser.admin 
                            ?
                            <TableCell>
                              <IconButton
                                onClick={() => handleDelete(b.id, b.uid)}
                              >
                                <Delete />
                              </IconButton>
                            </TableCell>
                            :
                            null
                          }
                      </TableRow>
                    )
                  })
                }
              </TableBody>
            </Table>
            <Pagination
              count={Math.ceil((search ? bookingsList : bookings).length / itemsPerPage)}
              page={page}
              onChange={(e, val) => setPage(val)}
              defaultPage={1}
              color="primary"
              size="large"
              showFirstButton
              showLastButton
            />  
          </CardContent>
          <CardActions>
            <Button
              variant='contained'
              color='primary'
              disabled={unconfirmed == 0}
              startIcon={<PersonPinCircleTwoTone />}
              onClick={() => confirmAllBookings(bookings, compId)}
            >Confirm all</Button>
            <CSVLink
                data={dataCSV}
                style={{ marginTop: 5, textDecoration: 'none'}}
                filename={`Competitions Bookings`}
            >
                <Button variant="contained" style={{ backgroundColor: 'green', color: '#fff'}}> Export view to CSV</Button>
            </CSVLink>
          </CardActions>
        </Card>
      }
      <Allocation open={open} close={handleClose} bkId={bkId} compId={compId} uid={uid}/>
    </div>
  )
}
