import { Button, Grid } from '@material-ui/core';
import { Event, EventNote } from '@material-ui/icons';
import React from 'react';
import { useState } from 'react';
import CompetitionList from './CompetitionList';
import NewComp from './NewComp';

export default function Competitions() {

  const [open, setOpen] = useState(false)

  const handleClick = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }
  return <div>
    <h1>Competitions</h1>
    <Button className='pm-right' startIcon={<Event />} variant='contained' color='primary' onClick={handleClick}>New Competitions</Button>
    <br /><br /><br />
    <CompetitionList />
    <NewComp open={open} handleClose={handleClose} />
  </div>;
}
