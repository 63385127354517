import { Button, CircularProgress, Dialog, DialogActions, DialogContent, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import { useDb } from '../../../../contexts/DatabaseContext'
import Select from 'react-select' 
import { selectFieldProps } from '../../../shared/consants'

export default function Allocation({ open, close, bkId, compId, uid }) {
  
  const { GetZones, GetPegs, updateBooking } = useDb()
  const zones = GetZones(compId)
  const pegs = GetPegs(compId)
  const [selectedZone, setSelectedZone] = useState(null)
  const [selectedPeg, setSelectedPeg] = useState(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  console.log(selectedZone, selectedPeg)

  const handleSave = async () => {
    setLoading(true)
    setError(null)
    try {
      await updateBooking(selectedPeg, selectedZone, bkId, uid, compId)
      .then(() => {
        setLoading(false)
        handleClose()
      })
    } catch (err) {
      setError(err.message)
      setLoading(false)
    }
  }

  const zoneOptions = zones && zones.map(z => ({
    value: z.zone,
    label: z.zone
  }))

  const pegOptions = pegs && pegs.map(p => ({
    value: p.peg,
    label: p.peg
  }))
  
  const handleClose = () => {
    setSelectedZone(null)
    setSelectedPeg(null)
    close()
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth >
      <DialogContent>
        <Typography>Select Zone</Typography>
        <Select 
          options={zoneOptions}
          {...selectFieldProps}
          fullWidth
          variant="outlined" 
          onChange={(val) => setSelectedZone(val.value)}
        />
        <Typography>Select Peg</Typography>
        <Select 
          options={pegOptions}
          {...selectFieldProps}
          fullWidth
          variant="outlined" 
          onChange={(val) => setSelectedPeg(val.value)}
        />
        {error ? <span style={{color: 'Red'}}>{error}</span> : null}
      </DialogContent>
      <DialogActions>
        <Button variant='contained' color='primary' onClick={handleSave} >{loading ? <CircularProgress /> : 'Allocate'}</Button>
        <Button variant='outlined' color='secondary' onClick={handleClose} >Cancel</Button>
      </DialogActions>
    </Dialog>
  )
}
