export const year = '2024';

export const version = '1.2.9'

export const elevation = 4

export const years = [
  { value: '2022', label: '2022' },
  { value: '2023', label: '2023' },
  { value: '2024', label: '2024' },
]

export const categories = [
  { value: 'Results', label: 'Results' },
  { value: 'Anglers', label: 'Anglers' },
  { value: 'Competitions', label: 'Competitions' },
  { value: 'Bookings', label: 'Bookings' },
  { value: 'Rankings', label: 'Rankings' },
  { value: 'Mobile', label: 'Mobile' }
];

export const roles = [
  { value: 'Admin', label: 'Admin' },
  { value: 'Angler', label: 'Angler' },
  { value: 'Host', label: 'Host' },
  { value: 'Marshall', label: 'Marshall' }
];

export const pmTypes = [
  {value: 'NM', label: 'NM'},
  {value: 'PNR', label: 'PNR'},
  {value: 'PNM', label: 'PNM'},
  {value: 'PNJ', label: 'PNJ'},
  {value: 'PN', label: 'PN'},
  {value: 'PND', label: 'PND'},
  {value: 'PNV', label: 'PNV'},
  {value: 'PNP', label: 'PNP'},
  {value: 'PNC', label: 'PNC'},
  {value: 'PJD', label: 'PJD'}
]

export const compTypes = [
  {value: 'Inland Individual', label: 'Inland Individual'},
  {value: 'Costal Individual', label: 'Costal Individual'},
  // {value: 'Pairs', label: 'Pairs'},
  // {value: 'Teams', label: 'Teams'}
]

const selectStyles = {
  menuPortal: base => ({ ...base, zIndex: 9999 }),
  control: styles => ({ ...styles, backgroundColor: 'white' }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      const color = 'black';
      return {
        ...styles,
        color
      }
  }
}

export const selectFieldProps = {
  menuPortalTarget: document.body,
  styles: selectStyles
}

export const selectItemsPerPage = [
  {value: 25, label: 25},
  {value: 50, label: 50},
  {value: 100, label: 100},
  {value: 200, label: 200},
  {value: 500, label: 500},
]

export const truncateString = (str,num) => {
  if(str.length <= num) {
      return str
  }
  else {
      return(str.slice(0, num) + '...')
  }
}

export const zonesArray = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
]