import React, { useState } from 'react'
import { useDb } from '../../../contexts/DatabaseContext'
import { TextField } from '@material-ui/core'
import Select from 'react-select'
import { selectFieldProps } from '../../shared/consants'

export default function AdminFunctions() {

  const { updateAllUsers, updateAllCatches, getMaxPmType, updateAllNotifications, updateAllComps, GetUsers, logBuyInResults } = useDb()
  const [buyInState, setBuyInState] = useState()
  const users = GetUsers()
  const userOptions = users.map(u => ({
    label: `${u.pmNumber} - ${u.firstName} ${u.lastName}`,
    value: {...u}
  }))

  const handleSelect = (val) => {
    setBuyInState({
      ...buyInState,
      ...val
    })
  }

  // async function test() {
  //   await getMaxPmType("RMLIveKadHaZAYW1LrXktpiGzzr1", "2022")
  // }

  const handleSubmit = () => {
    logBuyInResults(buyInState)
  }

  console.log(buyInState)

  return (
    <div>
      <h1>Admin Functions</h1>
      <button onClick={updateAllUsers}>Update All Users</button>
      <br />
      <button onClick={updateAllCatches}>Update All Catches</button>
      <br />
      <button onClick={updateAllNotifications}>Update All Notifications</button>
      <br />
      <button onClick={updateAllComps}>Update All Comps</button>
      <p>Add Buy In results</p>
      <Select
        options={userOptions}
        {...selectFieldProps}
        fullWidth
        variant="outlined" 
        onChange={(val) => handleSelect(val.value)}
      />
      <TextField
          label='pos'
          required 
          type='number'
          variant="outlined" 
          fullWidth 
          onChange={(e) => {
            setBuyInState({
              ...buyInState, 
              pos: parseInt(e.target.value)
            })
          }}
        />
        <br /><br />
        <TextField
          label='BMT'
          required 
          type='number'
          variant="outlined" 
          fullWidth 
          onChange={(e) => {
            setBuyInState({
              ...buyInState, 
              bmt: parseFloat(e.target.value)
            })
          }}
        />
        <br /><br />
        <TextField
          label='Standard Fish'
          required 
          type='number'
          variant="outlined" 
          fullWidth 
          onChange={(e) => {
            setBuyInState({
              ...buyInState,
              standardQty: parseInt(e.target.value)
            })
          }}
        />
        <br /><br />
        <TextField
          label='Standard weight'
          required 
          type='number'
          variant="outlined" 
          fullWidth 
          onChange={(e) => {
            setBuyInState({
              ...buyInState,
              standardWeight: parseFloat(e.target.value)
            })
          }}
        />
        <br /><br />
        <TextField
          label='yellow Fish'
          required 
          type='number'
          variant="outlined" 
          fullWidth 
          onChange={(e) => {
            setBuyInState({
              ...buyInState,
              yellowQty: parseInt(e.target.value)
            })
          }}
        />
        <br /><br />
        <TextField
          label='yellow weight'
          required 
          type='number'
          variant="outlined" 
          fullWidth 
          onChange={(e) => {
            setBuyInState({
              ...buyInState,
              yellowWeight: parseFloat(e.target.value)
            })
          }}
        />
        <br /><br />
        <TextField
          label='winnings'
          required
          type='number'
          variant="outlined" 
          fullWidth 
          onChange={(e) => {
            setBuyInState({
              ...buyInState,
              winnings: parseFloat(e.target.value)
            })
          }}
        />
        <button onClick={handleSubmit}>Submit</button>

        <br />
        <button onClick={test}>Test Max Pm Type</button>
    </div>
  )
}
