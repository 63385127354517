import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import { Delete, Timer } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useDb } from '../../contexts/DatabaseContext';
import moment from 'moment';

const TicketDetail = ({open, handleClose, ticketId}) => {

    const { getSupportTicket, deleteSupportTicket } = useDb()
    const [ticket, setTicket] = useState({})
    const [loading, setLoading] = useState(true)

    useEffect( async () => {
        const t = await getSupportTicket(ticketId)
        setTicket(t)
        setLoading(false)
    }, [ticketId])

    const handleDelete = async () => {
        await deleteSupportTicket(ticket.id)
        .then(() => {
            handleClose()
            window.location.reload()
        })
    }

    console.log(ticket)

    return (
        <Dialog open={open} onClose={handleClose} fullWidth>
            {
                loading
                ?
                <CircularProgress />
                :
                <>
                <DialogTitle>{ticket.title}</DialogTitle>
                <DialogContent>
                    <span className={`${ticket.label}`}>{ticket.label}</span>
                    <p>{ticket.description}</p>
                    <p>{ticket.user.firstName} {ticket.user.lastName}</p>
                    <p className='pm-right'>{moment(new Date(ticket.date)).fromNow()}</p>
                    <p>{ticket.path}</p>
                </DialogContent>
                <DialogActions className='pm-right'>
                    <Button startIcon={<Delete/>} onClick={() => handleDelete()} variant='outlined' color={'secondary'}>
                        Delete
                    </Button>
                </DialogActions>
                </>
            }
        </Dialog>
    );
}

export default TicketDetail;
