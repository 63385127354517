import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'
import { CancelSharp, PersonAdd } from '@material-ui/icons'
import { pmTypes, selectFieldProps } from '../shared/consants'
import Select from 'react-select'
import { useState } from 'react'
import { useDb } from '../../contexts/DatabaseContext'
import { useAuth } from '../../contexts/AuthContext'

export default function AddAngler({ open, close }) {

  const [user,setUser] = useState(null) 
  const [error,setError] = useState(null) 
  const { GetSponsors, GetPmNumbers } = useDb()
  const { signUp } = useAuth()
  const sponsors = GetSponsors()
  const pmNumbers = GetPmNumbers().map(pm => ({value: pm.sort_id, label: `${pm.sort_id} - ${pm.status}`}))

  const sponsorOptions = sponsors.map((s) => ({
    label: s.name,
    value: s
  }))

  const handleClose = () => {
    close()
  }

  const handleChange = (e) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value
    })
  }

  const handleSponsorSelect = (val) => {
    setUser({
      ...user,
      sponsorId: val.id,
      sponsorLogo: val.logoUrl,
      sponsorName: val.name
    })
  }

  const handlePmTypeSelect = (val) => {
    setUser({
      ...user,
      pmType: val
    })
  }

  const handlePmNumberSelect = (val) => {
    setUser({
      ...user,
      pmNumber: val
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setUser({
      ...user,
      display_name: `${user.firstName} ${user.lastName}`,
      created_time: new Date(),
      role: 'Angler'
    })
    try {
      await signUp(user.email, user.password, user)
      .then(() => handleClose())
    } catch (err) {
      setError(err.message)
    }
  }

  console.log(user)

  return (
    <Dialog open={open} onClose={() => {close()}} maxWidth="lg" fullWidth>
      <DialogTitle>
        Add Angler
      </DialogTitle>
      <form onSubmit={(e) => handleSubmit(e)}>
        <DialogContent>
            <TextField
              label='First Name'
              style={{marginBottom: 10}}
              required 
              autoCapitalize='true'
              name='firstName'
              variant="outlined" 
              type='text'
              fullWidth 
              onChange={(e) => handleChange(e)}
            />
            <TextField
              label='Last Name'
              style={{marginBottom: 10}}
              required 
              name='lastName'
              variant="outlined" 
              type='text'
              fullWidth 
              onChange={(e) => handleChange(e)}
            />
            <TextField
              label='Email'
              style={{marginBottom: 10}}
              required 
              name='email'
              variant="outlined" 
              type='text'
              fullWidth 
              onChange={(e) => handleChange(e)}
            />
            <TextField
              label='Password'
              style={{marginBottom: 10}}
              required 
              name='password'
              variant="outlined" 
              type='text'
              fullWidth 
              onChange={(e) => handleChange(e)}
            />
            <TextField
              label='ID Number'
              style={{marginBottom: 10}}
              required 
              name='idNumber'
              variant="outlined" 
              type='text'
              fullWidth 
              onChange={(e) => handleChange(e)}
            />
            <TextField
              label='Phone Number'
              style={{marginBottom: 10}}
              required 
              name='phone_number'
              variant="outlined" 
              type='text'
              fullWidth 
              onChange={(e) => handleChange(e)}
            />
            <Typography>Pm Type:* </Typography>
            <Select
              onChange={(val) => handlePmTypeSelect(val.value)}
              {...selectFieldProps}
              fullWidth
              options={pmTypes}
              variant="outlined" 
            ></Select>
            <Typography>Pm Number:* </Typography>
            <Select
              onChange={(val) => handlePmNumberSelect(val.value)}
              {...selectFieldProps}
              fullWidth
              options={pmNumbers}
              variant="outlined" 
            ></Select>
            <Typography>Sponsor:* </Typography>
            <Select
              onChange={(val) => handleSponsorSelect(val.value)}
              {...selectFieldProps}
              fullWidth
              options={sponsorOptions}
              variant="outlined" 
            ></Select>
            <br />
            <TextField
              label='City'
              style={{marginBottom: 10}}
              required 
              name='city'
              variant="outlined" 
              type='text'
              fullWidth 
              onChange={(e) => handleChange(e)}
            />
        </DialogContent>
        <DialogActions className='pm-right'>
          <Button onClick={handleClose} startIcon={<CancelSharp />} variant='contained' color='secondary'>Cancel</Button>
          <Button startIcon={<PersonAdd />} variant='contained' color='primary' type='submit'>Add</Button>
        </DialogActions>
        <Dialog open={error} onClose={() => {setError(null)}}>
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            <Typography>{error}</Typography>
          </Alert>
        </Dialog>
      </form>
    </Dialog>
  )
}
