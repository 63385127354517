import { Grid } from '@material-ui/core';
import React from 'react';
import SponsorActions from './SponsorActions';
import SponsorList from './SponsorList';

export default function Sponsors() {
  return <div>
    <h1>Sponsors</h1>
    <SponsorActions />
    <br />
    <br />
    <SponsorList />
  </div>;
}
