import { Button } from '@material-ui/core'
import { ArrowBack } from '@material-ui/icons'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Importer, ImporterField } from "react-csv-importer"
import "react-csv-importer/dist/index.css";
import { useDb } from '../../../contexts/DatabaseContext'
import { useParams } from 'react-router-dom'

export default function ImportBookings() {

  const { compId } = useParams()
  const { requestImportBookings } = useDb()
  const [impArray, setImpArray] = useState([])
  const history = useHistory()
  let tempArray = []

  return (
    <div>
      <h1>Import Bookings</h1>
      <Button startIcon={<ArrowBack />} variant='contained' color='primary' onClick={() => history.goBack()}>Back</Button>
      <br /><br />
      <Importer
        processChunk={async rows => {
          for (var row of rows) {
            tempArray.push(row)
          }
          // setState with temp array
          setImpArray(tempArray)
        }}
        onComplete={async () => {
          await requestImportBookings(impArray, compId)
          .then(() => {history.goBack()})
        }}
      >
        <ImporterField name="pmType" label="Type" />
        <ImporterField name="pmNumber" label="PM Nr." />
        <ImporterField name="firstName" label="Name" />
        <ImporterField name="lastName" label="Surname" />
        <ImporterField name="entryFee" label="Entry Fee" />
      </Importer>
    </div>
  )
}
