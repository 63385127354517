import { Button, Card, CardContent, Dialog, DialogActions, DialogContent, TextField } from '@material-ui/core'
import { Image } from '@material-ui/icons'
import React, { useState } from 'react'
import { useDb } from '../../contexts/DatabaseContext'
import FileUploader from '../../shared/FileUploader'

export default function SponsorActions() {
    const { addSponsor } = useDb()
    const [open, setOpen] = useState(false)
    const [name, setName] = useState('')
    const [url, setUrl] = useState(null)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    const getUrl = (url) => {
        setUrl(url)
    }

    const handleSubmit = async () => {
        setLoading(true)
        try {
            await addSponsor({
                logoUrl: url,
                name
            })
            setOpen(false)
        }
        catch(err) {
            setError(err.message)
            console.log(err.message)
            setLoading(false)
        }
        
    }

    return (
        <div>
            <Button className='pm-right' startIcon={<Image />} onClick={() => setOpen(true)} color="primary" variant="contained">Add Sponsor</Button>
            <br />
            <Dialog maxWidth="sm" fullWidth open={open} onClose={() => setOpen(false)}>
                <DialogContent>
                    <TextField fullWidth variant="outlined" label="Sponsor Name" onChange={(e) => setName(e.target.value)} />
                    <br/><br/>
                    <FileUploader getUrl={getUrl}/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSubmit} disabled={loading ? true : false || name === null ? true : false || url === null ? true : false } color="primary">Confirm</Button>
                    <Button onClick={() => setOpen(false)} color="secondary">Cancel</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
