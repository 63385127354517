import { Button, Dialog, DialogActions, DialogContent } from '@material-ui/core'
import { ArrowBack } from '@material-ui/icons'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Importer, ImporterField } from "react-csv-importer"
import "react-csv-importer/dist/index.css";
import { useDb } from '../../contexts/DatabaseContext'

export default function ImportAnglers() {

  const { requestImportAnglers } = useDb()
  const [impArray, setImpArray] = useState([])
  const [open, setOpen] = useState(false)
  const history = useHistory()
  let tempArray = []

  const handleClose = () => {
    setOpen(false)
    history.goBack()
  }

  return (
    <div>
      <h1>Import Anglers</h1>
      <Button startIcon={<ArrowBack />} variant='contained' color='primary' onClick={() => history.goBack()}>Back</Button>
      <br /><br />
      <Importer
        processChunk={async rows => {
          for (var row of rows) {
            tempArray.push(row)
          }
          // setState with temp array
          setImpArray(tempArray)
        }}
        onComplete={async () => {
          await requestImportAnglers(impArray)
          .then(() => setOpen(true))
        }}
      >
        <ImporterField name="display_name" label="Display Name" />
        <ImporterField name="firstName" label="First Name" />
        <ImporterField name="lastName" label="Last Name" />
        <ImporterField name="email" label="Email" />
        <ImporterField name="password" label="Password" />
        <ImporterField name="pmNumber" label="Pm Number" />
        <ImporterField name="pmType" label="Pm Type" />
        <ImporterField name="sponsor" label="Sponsor" />
      </Importer>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          {`${impArray.length} users Imported`}
        </DialogContent>
        <DialogActions className='pm-right'>
          <Button variant='contained' color='primary' onClick={handleClose}>Done</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
