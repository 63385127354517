import { Button, Card, CardActionArea, CardActions, CardContent, CardHeader, CircularProgress, Grid, IconButton, makeStyles, Popper, Typography } from '@material-ui/core'
import { ArrowBack, Delete, Edit, EditAttributes } from '@material-ui/icons'
import moment from 'moment'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import { useDb } from '../../contexts/DatabaseContext'
import { useAuth } from '../../contexts/AuthContext'
import EditComp from './EditComp'
import { elevation } from '../shared/consants'

const useStyles = makeStyles((theme) => ({
  paper: {
    border: '1px solid',
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function CompetitionsDetails() {

  const classes = useStyles();
  const history = useHistory()
  const { compId } = useParams()
  const { currentUser } = useAuth()
  const { getComp, getHost, deleteComp, closeComp } = useDb()
  const [loading, setLoading] = useState(true)
  const [error,setError] = useState(null)
  const [comp, setComp] = useState({})
  const [host, setHost] = useState(null)
  const [open, setOpen] = useState(false)
  const [updated, setUpdated] = useState(1)
  const [state, setState] = useState({
    field: null,
    type: null,
    data: null
  })
  const [anchorEl, setAnchorEl] = useState(null)
  useEffect( async () => {
    setLoading(true)
    try {
      const data = await getComp(compId)
      setComp(data)
      const user = await getHost(data.host)
      setHost(user)
    } catch (error) {
      setError(error.message)
      setLoading(false)
    }
    setLoading(false)
  }, [updated])

  const handleDelete = async (compId, e) => {
    await deleteComp(compId)
    setAnchorEl(anchorEl ? null : e.currentTarget)
    history.goBack()
  }

  const handleCompClose = async () => {
    await closeComp(compId)
    setUpdated(Math.random().toFixed(0))
  }

  const handleClick = (e) => {
    setAnchorEl(anchorEl ? null : e.currentTarget);
  }

  const openAnchor = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  const handleEdit = (field, type, data) => {
    setState({
      field: field,
      type: type,
      data: data
    })
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div>
      <h1>Competition Details</h1>
      <Button startIcon={<ArrowBack />} variant='contained' color='primary' onClick={() => history.goBack()}>Back</Button>
      <br />
      <br />
      {
        loading
        ?
        <CircularProgress />
        :
        <Card elevation={elevation} style={{borderRadius: 10}}>
          <CardHeader action={
            currentUser.role === "Admin" ? 
            <Button
            className='pm-right'
            aria-describedby={id}
            onClick={handleClick}
            startIcon={<Delete />}
            variant='outlined' 
            color='secondary'
          >Delete</Button>
          :
          null
          } title={`${comp.name} - ${moment(comp.date.toDate()).format('DD MMM YYYY')}`} /> 
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={6}>
                <span style={{fontSize: 18}}>Name: {comp.name}</span> 
                <IconButton onClick={() => {
                  handleEdit('name', 'text', comp.name)
                  setOpen(true)
                }}><Edit /></IconButton>
                <br />
                <span style={{fontSize: 18}}>Venue: {comp.venue}</span> 
                <IconButton onClick={() => {
                  handleEdit('venue', 'text', comp.venue)
                  setOpen(true)
                }}><Edit /></IconButton>
                <br />
                <span style={{fontSize: 18}}>Date: {moment(comp.date.toDate()).format('DD/MM/YY')}</span> 
                <IconButton onClick={() => {
                  handleEdit('date', 'date', comp.date)
                  setOpen(true)
                }}><Edit /></IconButton>
                <br />
                <span style={{fontSize: 18}}>Start Time: {moment(comp.startTime.toDate()).format('HH:mm')}</span> 
                <IconButton onClick={() => {
                  handleEdit('startTime', 'time', comp.startTime)
                  setOpen(true)
                }}><Edit /></IconButton>
                <br />
                <span style={{fontSize: 18}}>End Time: {moment(comp.endTime.toDate()).format('HH:mm')}</span> 
                <IconButton onClick={() => {
                  handleEdit('endTime', 'time', comp.endTime)
                  setOpen(true)
                }}><Edit /></IconButton>
                <br />
                <span style={{fontSize: 18}}>Angling Start: {moment(comp.anglingStart.toDate()).format('HH:mm')}</span> 
                <IconButton onClick={() => {
                  handleEdit('anglingStart', 'time', comp.anglingStart)
                  setOpen(true)
                }}><Edit /></IconButton>
                <br />
                <span style={{fontSize: 18}}>Angling End: {moment(comp.anglingEnd.toDate()).format('HH:mm')}</span> 
                <IconButton onClick={() => {
                  handleEdit('anglingEnd', 'time', comp.anglingEnd)
                  setOpen(true)
                }}><Edit /></IconButton>
                <br />
                <span style={{fontSize: 18}}>Total Pegs: {comp.pegs}</span>
                  <IconButton onClick={() => {
                    handleEdit('pegs', 'number', comp.pegs)
                    setOpen(true)
                  }}><Edit /></IconButton>
                <br />
                <span style={{fontSize: 18}}>Total Zones: {comp.zones}</span>
                  <IconButton onClick={() => {
                    handleEdit('zones', 'number', comp.zones)
                    setOpen(true)
                  }}><Edit /></IconButton>                
                <br />
              </Grid>
              <Grid item md={6}>
                <span style={{fontSize: 18}}>Entry Fee: {`R${comp.entryFee}`}</span> 
                <IconButton onClick={() => {
                  handleEdit('entryFee', 'number', comp.entryFee)
                  setOpen(true)
                }}><Edit /></IconButton>
                <br />
                <span style={{fontSize: 18}}>No Pot Fee: {`R${comp.noPotFee}`}</span> 
                <IconButton onClick={() => {
                  handleEdit('noPotFee', 'number', comp.noPotFee)
                  setOpen(true)
                }}><Edit /></IconButton>
                <br />
                <span style={{fontSize: 18}}>Gate Fee: {`R${comp.gateFee}`}</span> 
                <IconButton onClick={() => {
                  handleEdit('gateFee', 'number', comp.gateFee)
                  setOpen(true)
                }}><Edit /></IconButton>
                <br />
                <span style={{fontSize: 18}}>Notes: </span> <IconButton onClick={() => {
                  handleEdit('notes', 'text', comp.notes)
                  setOpen(true)
                }}><Edit /></IconButton>
                <p style={{marginTop: 0, fontSize: 16}}>{comp.notes}</p>
                <span style={{fontSize: 18}}>Host: {`${host.firstName} ${host.lastName}`}</span> 
                <IconButton onClick={() => {
                  handleEdit('host', 'host-drop-down', comp.host)
                  setOpen(true)
                }}><Edit /></IconButton>
                <br />
                <span style={{fontSize: 18}}>Host Email: {comp.hostEmail}</span> 
                <IconButton onClick={() => {
                  handleEdit('hostEmail', 'text', comp.hostEmail)
                  setOpen(true)
                }}><Edit /></IconButton>
                <br />
                <span style={{fontSize: 18}}>Type: {comp.type}</span> 
                <IconButton onClick={() => {
                  handleEdit('type', 'drop-down', comp.type)
                  setOpen(true)
                }}><Edit /></IconButton>
                <br />
                {
                  comp.type == 'Teams'
                  ? 
                  <>
                    <span style={{fontSize: 18}}>Max team members: {comp.teamMax}</span> 
                    <IconButton onClick={() => {
                      handleEdit('teamMax', 'number', comp.teamMax)
                      setOpen(true)
                    }}><Edit /></IconButton>
                  </>
                  :
                  null
                }
              </Grid>
            </Grid>
          </CardContent>
          <CardActions className='pm-right' >
            <Button disabled={!comp.availablePegs >= 1} color='secondary' variant='contained' onClick={handleCompClose}>Close Comp</Button>
          </CardActions>
        </Card>
      }
      <span style={{color: 'red'}}>{error ? error : null}</span>
      <EditComp open={open} handleClose={handleClose} field={state.field} type={state.type} data={state.data} compId={compId} updated={updated} setUpdated={setUpdated} />

      <Popper placement='left' id={id} open={openAnchor} anchorEl={anchorEl} >
        <Card elevation={5}>
          <CardContent>
            <center><h2>Are You Sure</h2></center>
            <p style={{fontSize: 18}}><b>This Action Cannot be undone</b></p>
          </CardContent>
          <CardActions>
            <Button variant='contained' color='primary' onClick={(e) => {
              handleDelete(compId)
              }}>Yes, Delete</Button>
            <Button variant='outlined' color='secondary' onClick={handleClick}>Cancel</Button>
          </CardActions>
        </Card>
      </Popper>
    </div>
  )
}