import { Button, Card, CardActions, CardContent, Grid, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@material-ui/core'
import { Pagination } from '@material-ui/lab'
import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { useDb } from '../../contexts/DatabaseContext'
import { elevation, selectItemsPerPage } from '../shared/consants'

export default function PmNumberList() {

  const { GetPmNumbers } = useDb()
  const pmNumbers = GetPmNumbers()
  const [numbers, setNumbers] = useState(pmNumbers && pmNumbers)
  const [search, setSearch] = useState('')

  console.log(pmNumbers)

  // Pagination
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [page, setPage] = useState(1)
  const [noOfPages] = useState(
    Math.ceil((search ? numbers : pmNumbers).length / itemsPerPage)
  )

  useEffect(() => {
    let tempArray = []
    pmNumbers.map(c => {
      if(
        c.sort_id.toString().toLowerCase().includes(search.toLowerCase()) ||
        c.status.toLowerCase().includes(search.toLowerCase())
      ) {
        setPage(1)
        tempArray.push(c)
      }
    })
    setNumbers(tempArray)
  }, [search])

  const handleUnclaim = (ref) => {
    ref.update({
      status: "unclaimed"
    })
  }

  return (
    <Card elevation={elevation} style={{borderRadius: 10}}>
      <CardContent>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={10}>
            <TextField
              label='Search'
              type='text'
              variant="outlined" 
              fullWidth 
              onChange={(e) => setSearch(e.target.value)}
              />
          </Grid>
          <Grid item xs={12} sm={2}>
            Items Per Page
            <Select options={selectItemsPerPage} onChange={(val) => {
              setItemsPerPage(val.value)
              setPage(1)
            }}/>
          </Grid>
        </Grid>
        <Table>
          <TableHead>
            <TableCell>PmNumber</TableCell>
            <TableCell>Status</TableCell>
            <TableCell></TableCell>
          </TableHead>
          <TableBody>
            { 
              (search ? numbers : pmNumbers)
              .slice((page -1) * itemsPerPage, page * itemsPerPage)
              .map(pmNumber => {
                return(
                  <TableRow key={pmNumber.sort_id}>
                    <TableCell>{pmNumber.sort_id}</TableCell>
                    <TableCell>{pmNumber.status}</TableCell>
                    <TableCell><Button variant='contained' color='primary' onClick={() => handleUnclaim(pmNumber.ref)}>Unclaim</Button></TableCell>
                  </TableRow>
                )
              })
            }
          </TableBody>
        </Table>
      </CardContent>
      <CardActions>
          <Pagination
            count={Math.ceil((search ? numbers : pmNumbers).length / itemsPerPage)}
            page={page}
            onChange={(e, val) => setPage(val)}
            defaultPage={1}
            color="primary"
            size="large"
            showFirstButton
            showLastButton
          />  
        </CardActions>
    </Card>
  )
}
