import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { year, zonesArray } from "../components/shared/consants";
import { db, FieldValue } from "../firebase";
import { useAuth } from "./AuthContext";
import firebase from "firebase";

const DatabaseContext = React.createContext();

export function useDb() {
  return useContext(DatabaseContext);
}

export function DatabaseProvider({ children }) {
  const { currentUser } = useAuth();

  // ------------------------------ Set / Delete Data ------------------------------ //

  async function createComp(data, zones) {
    console.log(data, zones);
    let createdDoc;
    await db
      .collection("ff_competitions")
      .add({
        ...data,
        availablePegs: parseInt(data.pegs),
        weighIns: 0,
        confirmedEntries: 0,
        published: false,
        archived: false,
        anglers: [],
        marshalls: [],
        zones: zones,
      })
      .then((doc) => {
        db.collection("ff_competitions").doc(doc.id).update({
          compId: doc.id,
        });
        createdDoc = doc;
      });

    for (let i = 0; i < zones; i++) {
      const zone = zonesArray[i];

      await db.collection("ff_zones").add({
        comp: createdDoc.id,
        compRef: createdDoc.path,
        zone: zone,
      });
    }

    for (let i = 1; i <= data.pegs; i++) {
      await db.collection("ff_pegs").add({
        comp: createdDoc.id,
        compRef: createdDoc.path,
        peg: i,
        weighed: false,
      });
    }

    await logs(
      currentUser,
      `Competition Created: ${data.name} ${moment(data.date).format("DD/MM/YY")}`
    );
  }

  async function bookUser(data) {
    console.log(data);
    const user = await db
      .collection("users")
      .doc(data.uid)
      .get()
      .then((doc) => ({
        ref: doc.ref,
        ...doc.data(),
      }));
    const comp = await data.compRef.get().then((doc) => ({
      ...doc.data(),
      ref: doc.ref,
    }));
    console.log(comp);
    await db.collection("ff_bookingRequest").add({ ...data });
    await notification(
      "MyComp",
      `You have been booked for a competition (${comp.name}) on ${moment(
        comp.date.toDate()
      ).format("DD/MM/YYYY")}`,
      "Your Booked For a Competition",
      `{"compId": ${comp.id}}`,
      user.ref
    );
    await logs(
      currentUser,
      `User Booked: Comp: (${comp.name} ${moment(comp.date).format("DD/MM/YY")}) User: (${user.firstName} ${user.lastName})`
    );
  }

  async function addPmNumber(num) {
    db.collection("pmNumbers")
      .doc(`${num}`)
      .get()
      .then((doc) => {
        if (doc.exists) {
          console.log("Doc exists");
          return null;
        } else {
          doc.ref.set({
            status: "unclaimed",
            sort_id: parseInt(num),
          });
        }
      });
    await logs(currentUser, `Pm Number Created: ${num}`);
  }

  async function addSponsor(sponsor) {
    db.collection("sponsors")
      .add({
        ...sponsor,
      })
      .then((doc) => {
        db.collection("sponsors").doc(doc.id).update({
          sponsorId: doc.id,
        });
      });
    await logs(currentUser, `Sponsor Created: ${sponsor.name}`);
  }

  async function deleteSponsor(sponsorId) {
    const sponsor = await db
      .collection("sponsors")
      .doc(sponsorId)
      .get()
      .then((doc) => ({
        ref: doc.ref,
        ...doc.data(),
      }));

    await db.collection("sponsors").doc(sponsorId).delete();

    await logs(currentUser, `Sponsor Deleted: ${sponsor.name}`);
  }

  async function deleteResult(res) {
    const result = await db
      .collection("ff_catches")
      .doc(res.id)
      .get()
      .then((doc) => ({
        ref: doc.ref,
      }));
    const comp = await res.compRef.get().then((doc) => ({
      ref: doc.ref,
      ...doc.data(),
    }));
    const booking = await db
      .collection("ff_bookingRequest")
      .where("compId", "==", comp.ref.id)
      .where("uid", "==", res.uid)
      .get()
      .then((querySnapshot) => ({
        ref: querySnapshot.docs[0].ref,
        ...querySnapshot.docs[0].data(),
      }));
    booking.ref.update({
      logged: false,
    });

    comp.ref.update({
      weighIns: FieldValue.increment(-1),
    });

    await result.ref.delete().then(() => {
      logs(
        currentUser,
        `Result Deleted: Comp: (${comp.name} ${moment(comp.date).format("DD/MM/YY")}) User: (${res.display_name})`
      );
    });
  }

  async function deleteUser(userId) {
    const user = await db
      .collection("users")
      .doc(userId)
      .get()
      .then((doc) => ({
        ref: doc.ref,
        ...doc.data(),
      }));

    user.ref.delete();

    await logs(currentUser, `User Deleted: ${user.firstName} ${user.lastName}`);
  }

  async function deleteComp(compId) {
    const comp = await db
      .collection("ff_competitions")
      .doc(compId)
      .get()
      .then((doc) => ({
        ref: doc.ref,
        ...doc.data(),
      }));

    comp.ref
      .delete()
      .then(async () => {
        await db
          .collection("ff_zones")
          .where("compId", "==", compId)
          .onSnapshot((snap) => {
            const docs = snap.docs;
            for (let i = 0; i < docs.length; i++) {
              const doc = docs[i];
              return doc.ref.delete();
            }
          });
      })
      .then(async () => {
        await db
          .collection("ff_pegs")
          .where("compId", "==", compId)
          .onSnapshot((snap) => {
            const docs = snap.docs;
            for (let i = 0; i < docs.length; i++) {
              const doc = docs[i];
              return doc.ref.delete();
            }
          });
      })
      .then(() => {
        db.collection("ff_catches")
          .where("compId", "==", compId)
          .onSnapshot((snap) => {
            const docs = snap.docs;
            for (let i = 0; i < docs.length; i++) {
              const doc = docs[i];
              return doc.ref.delete();
            }
          });
      });
    await logs(
      currentUser,
      `Competition Deleted: ${comp.name} ${moment(comp.date.toDate()).format(
        "DD/MM/YY"
      )}`
    );
  }

  function publishResults(compId, bool) {
    db.collection("ff_competitions").doc(compId).update({
      published: bool,
    });
  }

  async function setMarshall(compId, uid) {
    db.collection("ff_competitions")
      .doc(compId)
      .get()
      .then((doc) => {
        if (doc.data().marshalls.includes(uid)) {
          return null;
        } else {
          doc.ref.update({
            marshalls: FieldValue.arrayUnion(uid),
          });
          return true;
        }
      });
  }

  async function logResults(res) {
    const comp = await db
      .collection("ff_competitions")
      .doc(res.compId)
      .get()
      .then((doc) => ({
        ref: doc.ref,
        ...doc.data(),
      }));
    await db.collection("ff_catches").add({
      ...res,
      year: year,
      totalFish: res.standardQty + res.yellowQty,
      totalWeight:
        res.standardWeight + res.yellowWeight,
      score:
        (res.standardQty + res.yellowQty) * 10 +
        (res.standardWeight + res.yellowWeight) * 10,
    });

    await db.collection("ff_bookings").doc(res.id).update({
      logged: true,
    });

    await notification(
      "Home",
      `Your Results has been Loaded for ${comp.name} on ${moment(
        comp.date.toDate()
      ).format("DD/MM/YYYY")}`,
      "Results Loaded",
      ` `,
      res.userRef
    );
    await logs(
      currentUser,
      `Log Result: Comp: (${comp.name} ${moment(comp.date).format("DD/MM/YY")}) Angler: (${res.display_name} - ${res.email})`
    );
  }

  async function logBuyInResults(res) {
    await db.collection("ff_catches").add({
      ...res,
      compId: 'buyin',
      compRef: 'ff_competitions/buyin',
      entryFee: 110,
      marshall: 'marchallbuyin',
      peg: 1,
      zone: 'A',
      year: year,
      totalFish: res.standardQty + res.yellowQty,
      totalWeight:
        res.standardWeight + res.yellowWeight,
      score:
        (res.standardQty + res.yellowQty) * 10 +
        (res.standardWeight + res.yellowWeight) * 10,
    });
  }

  async function requestImportResults(data, compId) {
    const comp = await db
      .collection("ff_competitions")
      .doc(compId)
      .get()
      .then((doc) => ({
        ref: doc.ref,
        ...doc.data(),
      }));

    await db.collection("ff_importResultReq").add({
      compId: compId,
      marshall: currentUser.uid,
      data: data,
    });
    await logs(currentUser, `Import Results: Comp: ${comp.name} ${moment(comp.date).format("DD/MM/YY")}`);
  }

  async function requestImportAnglers(data) {
    await db.collection("ff_importUserReq").add({
      data: data,
    });
    await logs(currentUser, `Import Anglers`);
  }

  async function requestImportBookings(data, compId) {
    const comp = await db
      .collection("ff_competitions")
      .doc(compId)
      .get()
      .then((doc) => ({
        ref: doc.ref,
        ...doc.data(),
      }));
    await db.collection("ff_importBookingReq").add({
      compId: compId,
      data: data,
    });
    await logs(currentUser, `Import Bookings: Comp: ${comp.name} ${moment(comp.date).format("DD/MM/YY")}`);
  }

  async function deleteBooking(bkId, compId, uid) {
    const user = await db
      .collection("users")
      .doc(uid)
      .get()
      .then((doc) => ({
        ref: doc.ref,
        ...doc.data(),
      }));
    const comp = await db
      .collection("ff_competitions")
      .doc(compId)
      .get()
      .then((doc) => ({
        ref: doc.ref,
        ...doc.data(),
      }));
      const booking = await db
      .collection("ff_competitions")
      .doc(compId)
      .get()
      .then(bk => ({
        ...bk.data()
      }))
      if (booking.confirmed) {
        await db
      .collection("ff_competitions")
      .doc(compId)
      .update({
        availablePegs: FieldValue.increment(1),
        confirmedEntries: FieldValue.increment(-1),
        anglers: FieldValue.arrayRemove(uid),
      });
      } else {
        await db
      .collection("ff_competitions")
      .doc(compId)
      .update({
        availablePegs: FieldValue.increment(1),
        anglers: FieldValue.arrayRemove(uid),
      });
      }
    

    await db.collection("ff_bookings").doc(bkId).delete();

    await notification(
      "Home",
      `Your Booking has been canceled for ${comp.name} on ${moment(
        comp.date.toDate()
      ).format("DD/MM/YYY")}`,
      "Booking Canceled",
      ` `,
      user.ref
    );
    await logs(
      currentUser,
      `Booking Canceled: Comp: (${comp.name} ${moment(comp.date).format("DD/MM/YY")}) Booking: (${user.firstName} ${user.lastName})`
    );
  }

  // Support Tickets

  function createTicket(ticket) {

    db.collection('support_tickets')
    .add({
      ...ticket
    })

  }

  function updateTicketStatus(id, status) {
    return db.collection('support_tickets')
    .doc(id)
    .update({
      status: status
    })
  }

  async function GetSupportTickets(status) {
    const res = await db.collection('support_tickets')
    .where('status', '==', status)
    .get()
    .then(snap => {
      return snap.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }))
    })
    return res
  }

  async function getSupportTicket(ticketId) {
    return await db.collection('support_tickets')
    .doc(ticketId)
    .get()
    .then(doc => ({
      ...doc.data(),
      id: doc.id
    }))
  }
  
  async function deleteSupportTicket(ticketId) {
    return await db.collection('support_tickets')
    .doc(ticketId)
    .delete()
  } 

  // ------------------------------ Get Data ------------------------------ //

  function GetUsers() {
    const [users, setUsers] = useState([]);

    useEffect(() => {
      const unsubscribe = db
        .collection("users")
        .orderBy("pmNumber", "asc")
        .onSnapshot((snapshot) => {
          const userList = snapshot.docs.map((doc) => ({
            ...doc.data(),
            pmNumber: parseInt(doc.data().pmNumber),
            uid: doc.id,
            ref: doc.ref,
          }));
          setUsers(userList);
        });
      return unsubscribe;
    }, []);
    return users;
  }

  function GetAnglers() {
    const [users, setUsers] = useState([]);

    useEffect(() => {
      const unsubscribe = db
        .collection("users")
        .orderBy("pmNumber", "asc")
        .onSnapshot((snapshot) => {
          const userList = snapshot.docs.map((doc) => ({
            ...doc.data(),
            userRef: doc.ref,
            uid: doc.id,
          }));
          setUsers(userList);
        });
      return unsubscribe;
    }, []);
    return users;
  }

  function GetMarshalls() {
    const [users, setUsers] = useState([]);

    useEffect(() => {
      const unsubscribe = db
        .collection("users")
        .where("role", "==", "Marshall")
        .onSnapshot((snapshot) => {
          const userList = snapshot.docs.map((doc) => ({
            ...doc.data(),
            uid: doc.id,
            ref: doc.ref,
          }));
          setUsers(userList);
        });
      return unsubscribe;
    }, []);
    return users;
  }

  function GetHosts() {
    const [users, setUsers] = useState([]);

    useEffect(() => {
      const unsubscribe = db
        .collection("users")
        .where("role", "==", "Host")
        .onSnapshot((snapshot) => {
          const userList = snapshot.docs.map((doc) => ({
            ...doc.data(),
            uid: doc.id,
            ref: doc.ref,
          }));
          setUsers(userList);
        });
      return unsubscribe;
    }, []);
    return users;
  }

  function GetHostsDropDown() {
    const [users, setUsers] = useState([]);

    useEffect(() => {
      const unsubscribe = db
        .collection("users")
        .where("role", "==", "Host")
        .onSnapshot((snapshot) => {
          const userList = snapshot.docs.map((doc) => ({
            label: doc.data().display_name,
            value: doc.id,
            ref: doc.ref,
          }));
          setUsers(userList);
        });
      return unsubscribe;
    }, []);
    return users;
  }

  function GetPmNumbers() {
    const [pmNumbers, setPmNumbers] = useState([]);

    useEffect(() => {
      const unsubscribe = db
        .collection("pmNumbers")
        .orderBy("sort_id", "asc")
        .onSnapshot((snapshot) => {
          const numberList = snapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
            ref: doc.ref,
          }));
          setPmNumbers(numberList);
        });
      return unsubscribe;
    }, []);
    return pmNumbers;
  }

  function GetSponsors() {
    const [sponsors, setSponsors] = useState([]);

    useEffect(() => {
      const unsubscribe = db
        .collection("sponsors")
        .orderBy("name", "asc")
        .onSnapshot((snapshot) => {
          const sponsorList = snapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
            ref: doc.ref,
          }));
          setSponsors(sponsorList);
        });
      return unsubscribe;
    }, []);
    return sponsors;
  }

  async function getSponsor(sponsorId) {
    await db
      .collection("sponsors")
      .doc(sponsorId)
      .get()
      .then((doc) => ({
        ...doc.data(),
        id: doc.id,
        ref: doc.ref,
      }));
  }

  function GetComps() {
    const [comps, setComps] = useState([]);

    useEffect(() => {
      const unsubscribe = db
        .collection("ff_competitions")
        .where('archived', '==', false)
        .orderBy("date", "desc")
        .onSnapshot(async(snapshot) => {
          const compList = snapshot.docs.map(async (doc) => ({
            totalBookings: await (await db.collection('ff_bookings').where('compId', '==', doc.id).get()).docs.length,
            id: doc.id,
            ref: doc.ref,
            ...doc.data(),
          }));
          setComps(await Promise.all(compList));
        });
      return unsubscribe;
    }, []);
    return comps;
  }

  function GetArchivedComps() {
    const [comps, setComps] = useState([]);

    useEffect(() => {
      const unsubscribe = db
        .collection("ff_competitions")
        .where('archived', '==', true)
        .orderBy("date", "desc")
        .onSnapshot(async(snapshot) => {
          const compList = snapshot.docs.map(async (doc) => ({
            totalBookings: await (await db.collection('ff_bookings').where('compId', '==', doc.id).get()).docs.length,
            id: doc.id,
            ref: doc.ref,
            ...doc.data(),
          }));
          setComps(await Promise.all(compList));
        });
      return unsubscribe;
    }, []);
    return comps;
  }

  function GetHostComps(id) {
    console.log("host");
    const [comps, setComps] = useState([]);

    useEffect(() => {
      const unsubscribe = db
        .collection("ff_competitions")
        .where('archived', '==', false)
        .where("host", "==", id)
        .orderBy("date", "desc")
        .onSnapshot(async(snapshot) => {
          const compList = snapshot.docs.map(async (doc) => ({
            totalBookings: await (await db.collection('ff_bookings').where('compId', '==', doc.id).get()).docs.length,
            id: doc.id,
            ref: doc.ref,
            ...doc.data(),
          }));
          setComps(await Promise.all(compList));
        });
      return unsubscribe;
    }, []);
    return comps;
  }

  function GetArchivedHostComps(id) {
    console.log("host");
    const [comps, setComps] = useState([]);

    useEffect(() => {
      const unsubscribe = db
        .collection("ff_competitions")
        .where('archived', '==', true)
        .where("host", "==", id)
        .orderBy("date", "desc")
        .onSnapshot(async(snapshot) => {
          const compList = snapshot.docs.map(async (doc) => ({
            totalBookings: await (await db.collection('ff_bookings').where('compId', '==', doc.id).get()).docs.length,
            id: doc.id,
            ref: doc.ref,
            ...doc.data(),
          }));
          setComps(await Promise.all(compList));
        });
      return unsubscribe;
    }, []);
    return comps;
  }

  function GetRanking() {
    const [ranking, setRanking] = useState([]);

    useEffect(() => {
      const unsubscribe = db
        .collection(`ff_rankings`)
        .orderBy("bmtCalc", "desc")
        .onSnapshot((snapshot) => {
          const logList = snapshot.docs.map((doc) => ({
            id: doc.id,
            ref: doc.ref,
            ...doc.data(),
          }));
          setRanking(logList);
        });
      return unsubscribe;
    }, []);
    return ranking;
  }

  async function getComp(compId) {
    return await db
      .collection("ff_competitions")
      .doc(compId)
      .get()
      .then((doc) => ({
        ...doc.data(),
        compRef: doc.ref,
        id: doc.id,
      }));
  }

  async function getHost(uid) {
    return await db
      .collection("users")
      .doc(uid)
      .get()
      .then((doc) => ({
        uid: doc.id,
        ref: doc.ref,
        ...doc.data(),
      }));
  }

  async function getUser(uid) {
    return await db
      .collection("users")
      .doc(uid)
      .get()
      .then((doc) => ({
        ref: doc.ref,
        uid: doc.id,
        ...doc.data(),
      }));
  }

  async function getMarshallsPerComp(compId) {
    const marshalls = await db
      .collection("ff_competitions")
      .doc(compId)
      .get()
      .then((doc) => {
        return doc.data().marshalls;
      });

    let marshallArray = [];

    for (let i = 0; i < marshalls.length; i++) {
      const marshall = await getUser(marshalls[i]);
      marshallArray.push(marshall);
    }

    return marshallArray;
  }

  function GetBookings(compId) {
    const [bookings, setBookings] = useState([]);

    useEffect(() => {
      const unsubscribe = db
        .collection("ff_bookings")
        .where("compId", "==", compId)
        .orderBy("timestamp", "desc")
        .onSnapshot(async(snapshot) => {
          const bkList = snapshot.docs.map((doc) => ({
            id: doc.id,
            ref: doc.ref,
            ...doc.data(),
          }));
          const newList = bkList.map(async bk => ({
            ...bk,
            ... await bk.userRef.get().then(b => ({pmNumber: b.data().pmNumber, pmType: b.data().pmType}))
          }))
          console.log(await Promise.all(newList));
          setBookings(await Promise.all(newList));
        });
      return unsubscribe;
    }, []);
    return bookings;
  }

  function GetBookingsDropdown(compId) {
    const [bookings, setBookings] = useState([]);

    useEffect(() => {
      const unsubscribe = db
        .collection("ff_bookings")
        .where("compId", "==", compId)
        .where("logged", "==", false)
        .orderBy("timestamp", "desc")
        .onSnapshot((snapshot) => {
          const bkList = snapshot.docs.map((doc) => ({
            id: doc.id,
            ref: doc.ref,
            ...doc.data(),
          }));
          console.log(bkList);
          setBookings(bkList);
        });
      return unsubscribe;
    }, []);
    return bookings;
  }

  function GetTeams(compId) {
    const [teams, setTeams] = useState([]);

    useEffect(() => {
      const unsubscribe = db
        .collection("ff_teams")
        .where("compId", "==", compId)
        .onSnapshot((snapshot) => {
          const teamsList = snapshot.docs.map((doc) => ({
            ...doc.data(),
            ref: doc.ref,
            id: doc.id,
          }));
          console.log(teamsList);
          setTeams(teamsList);
        });
      return unsubscribe;
    }, []);
    return teams;
  }

  async function getTeamResults(compId) {
    const teams = await db
      .collection("ff_teams")
      .where("compId", "==", compId)
      .get()
      .then((doc) => {
        let tempArray = [];
        doc.docs.forEach((d) => tempArray.push({ ...d.data() }));
        return tempArray;
      });

    console.log(teams);
  }

  function GetResults(compId) {
    const [results, setResults] = useState([]);

    useEffect(() => {
      const unsubscribe = db
        .collection("ff_catches")
        .where("compId", "==", compId)
        .orderBy("pos", "asc")
        .onSnapshot((snapshot) => {
          const RList = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setResults(RList);
        });
      return unsubscribe;
    }, []);
    return results;
  }

  async function getResult(resId) {
    console.log(
      await db
      .collection("ff_catches")
      .doc(resId)
      .get()
      .then(res => (res.data()))
      )
    return await db
      .collection("ff_catches")
      .doc(resId)
      .get()
      .then((doc) => ({
        id: doc.id,
        ref: doc.ref,
        ...doc.data(),
      }));
  }

  function GetPegs(compId) {
    const [pegs, setPegs] = useState([]);

    useEffect(() => {
      const unsubscribe = db
        .collection("ff_pegs")
        .where("comp", "==", compId)
        .orderBy("peg", "asc")
        .onSnapshot((snapshot) => {
          const PList = snapshot.docs.map((doc) => ({
            ...doc.data(),
            ref: doc.ref,
            id: doc.id,
          }));
          setPegs(PList);
        });
      return unsubscribe;
    }, []);
    return pegs;
  }

  function GetZones(compId) {
    const [zones, setZones] = useState([]);

    useEffect(() => {
      const unsubscribe = db
        .collection("ff_zones")
        .where("comp", "==", compId)
        .orderBy("zone", "asc")
        .onSnapshot((snapshot) => {
          const PList = snapshot.docs.map((doc) => ({
            ...doc.data(),
            ref: doc.ref,
            id: doc.id,
          }));
          setZones(PList);
        });
      return unsubscribe;
    }, []);
    return zones;
  }

  function GetLogs() {
    const [logs, setLogs] = useState([]);

    useEffect(() => {
      const unsubscribe = db
        .collection("ff_logs")
        .orderBy("timestamp", "desc")
        .limit(1000)
        .onSnapshot((snapshot) => {
          const logList = snapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
            ref: doc.ref,
          }));
          setLogs(logList);
        });
      return unsubscribe;
    }, []);
    return logs;
  }

  async function getAnglerDetails(uid, y) {
    return db
      .collection("ff_catches")
      .where("uid", "==", uid)
      .where("year", "==", y)
      .orderBy("bmt", "desc")
      .get()
      .then((snap) => {
        const tempArray = snap.docs.map(async (doc) => {
          const data = doc.data();
          const user = await getUser(data.uid);
          const comp = await getComp(data.compId);
          return {
            ...data,
            ...user,
            ...comp,
            ref: doc.ref,
          };
        });
        return Promise.all(tempArray);
      });
  }

  async function getTotalComps(uid, y) {
    return db
      .collection("ff_catches")
      .where("uid", "==", uid)
      .where("year", "==", y)
      .get()
      .then((snap) => {
        return snap.docs.length;
      });
  }

  async function getTotalHostComps(uid, y) {
    return db
      .collection("ff_competitions")
      .where("host", "==", uid)
      .get()
      .then((snap) => {
        let counter = 0
        snap.docs.map(doc => {
          if (moment(doc.data().date.toDate()).isAfter(moment(new Date(2023, 11, 1))) && moment(doc.data().date.toDate()).isBefore(moment(new Date(2024, 10, 12)))) {
            counter++
          }
        })
        return counter;
      });
  }

  async function getMaxPmType(uid, y) {
    const test = await db
      .collection("ff_catches")
      .where("uid", "==", uid)
      .where("year", "==", y)
      .get()
      .then((snap) => {
        return snap.docs.map(doc => doc.data().compId)});
      console.log(await test);
  }

  async function getRangkingshttps() {
    const getRangkingsCall = firebase
      .functions()
      .httpsCallable("getRangkingsCall");
    const result = await getRangkingsCall().then((res) => {
      return res.data;
    });
    console.log(result[0]);
    const resData = await result[0];
    const rankings = resData.map(async (d) => {
      const user = await getUser(d.uid);
      const totalComps = await getTotalComps(d.uid, year);
      const bmt = d.bmt.sort((a, b) => b - a).slice(0, 8);
      const sum = bmt.reduce((accumulator, value) => {
        return accumulator + value;
      }, 0);
      const bmtCalc = sum / 8;

      return {
        id: user.uid,
        pmNumber: user.pmNumber,
        winnings: d.winnings,
        pmType: user.pmType,
        display_name: `${user.firstName} ${user.lastName}`,
        sponsorLogo: user.sponsorLogo,
        count: totalComps,
        bmtCalc: bmtCalc,
      };
    });
    console.log(await Promise.all(rankings));
    return await Promise.all(rankings);
  }
  
  async function getRangkingshttps2023() {
    const getRangkingsCall = firebase
      .functions()
      .httpsCallable("getRangkingsCall2023");
    const result = await getRangkingsCall().then((res) => {
      return res.data;
    });
    console.log(result[0]);
    const resData = await result[0];
    const rankings = resData.map(async (d) => {
      const user = await getUser(d.uid);
      const totalComps = await getTotalComps(d.uid, "2023");
      const bmt = d.bmt.sort((a, b) => b - a).slice(0, 8);
      const sum = bmt.reduce((accumulator, value) => {
        return accumulator + value;
      }, 0);
      const bmtCalc = sum / 8;

      return {
        id: user.uid,
        pmNumber: user.pmNumber,
        winnings: d.winnings,
        pmType: user.pmType,
        display_name: `${user.firstName} ${user.lastName}`,
        sponsorLogo: user.sponsorLogo,
        count: totalComps,
        bmtCalc: bmtCalc,
      };
    });
    console.log(await Promise.all(rankings));
    return await Promise.all(rankings);
  }

  async function getRangkingshttps2022() {
    const getRangkingsCall = firebase
      .functions()
      .httpsCallable("getRangkingsCall2022");
    const result = await getRangkingsCall().then((res) => {
      return res.data;
    });
    console.log(result[0]);
    const resData = await result[0];
    const rankings = resData.map(async (d) => {
      const user = await getUser(d.uid);
      const totalComps = await getTotalComps(d.uid, "2022");
      const bmt = d.bmt.sort((a, b) => b - a).slice(0, 8);
      const sum = bmt.reduce((accumulator, value) => {
        return accumulator + value;
      }, 0);
      const bmtCalc = sum / 8;

      return {
        id: user.uid,
        pmNumber: user.pmNumber,
        pmType: user.pmType,
        display_name: `${user.firstName} ${user.lastName}`,
        sponsorLogo: user.sponsorLogo,
        count: totalComps,
        bmtCalc: bmtCalc,
      };
    });
    console.log(await Promise.all(rankings));
    return await Promise.all(rankings);
  }

  // ------------------------------ Updating Data ------------------------------ //

  //update all catches with year
  async function updateAllCatches() {
    // const catches = await db
    //   .collection("ff_catches")
    //   .get()
    //   .then((doc) => {
    //     let tempArray = [];
    //     doc.docs.forEach((d) => tempArray.push({ ...d.data(), id: d.id }));
    //     return tempArray;
    //   });

    // const allcatches = catches.length;
    // let updated = 0;

    // for (let i = 0; i < catches.length; i++) {
    //   const catchItem = catches[i];
    //   await db
    //     .collection("ff_catches")
    //     .doc(catchItem.id)
    //     .update({
    //       ...catchItem,
    //       year: year,
    //     })
    //     .then(() => {
    //       updated++;
    //     });
    //   console.log(`${updated} / ${allcatches}`);
    // }
    console.log(`catches updated`);
  }

  async function updateAllUsers() {
    const users = await db
      .collection("users")
      .where("sponsorId", "==", "")
      .get()
      .then((doc) => {
        let tempArray = [];
        doc.docs.forEach((d) => tempArray.push({ ...d.data(), id: d.id, ref: d.ref}));
        return tempArray;
      });

    for (let i = 0; i < users.length; i++) {
      const user = users[i];
      let sponsorId = (await db.collection("sponsors").where("logoUrl", "==", user.sponsorLogo).get()).docs[0].id
      await db
        .collection("users")
        .doc(user.id)
        .update({
          sponsorId: sponsorId,
        });
      console.log(
        `${i} / ${users.length} ${((i / parseInt(users.length)) * 100).toFixed(
          2
        )}%`
      );
    }
    console.log("user update done");
  }

  async function updateAllNotifications() {

    const notifications = await db
      .collection("notifications")
      .get()
      .then((doc) => {
        let tempArray = [];
        doc.docs.forEach((d) => tempArray.push({ ...d.data(), id: d.id, ref: d.ref}));
        return tempArray;
      });

    notifications.forEach((noti, i) => {
      noti.ref.update({
        seen: false
      })
      console.log(
        `${i} / ${notifications.length} ${(((i + 1) / parseInt(notifications.length)) * 100).toFixed(
          2
        )}%`
      );
    })
  }

  async function updateAllComps() {

    const comps = await db
      .collection("ff_competitions")
      .get()
      .then((doc) => {
        let tempArray = [];
        doc.docs.forEach((d) => tempArray.push({ ...d.data(), id: d.id, ref: d.ref}));
        return tempArray;
      });

      comps.forEach((comp, i) => {
      comp.ref.update({
        archived: true
      })
      console.log(
        `${i} / ${comps.length} ${(((i + 1) / parseInt(comps.length)) * 100).toFixed(
          2
        )}%`
      );
    })
  }

  async function updateBooking(peg, zone, bkId, uid, compId) {
    const user = await db
      .collection("users")
      .doc(uid)
      .get()
      .then((doc) => ({
        ref: doc.ref,
        ...doc.data(),
      }));
    const comp = await db
      .collection("ff_competitions")
      .doc(compId)
      .get()
      .then((doc) => ({
        ref: doc.ref,
        ...doc.data(),
      }));

    return await db
      .collection("ff_bookings")
      .doc(bkId)
      .update({
        peg: peg,
        zone: zone,
      })
      .then(() => {
        notification(
          "MyComp",
          `Your Booking has been allocated for ${comp.name} on ${moment(
            comp.date.toDate()
          ).format("DD/MM/YYYY")}`,
          "Booking Allocated",
          `{"compId": ${compId}}`,
          user.ref
        );
        logs(
          currentUser,
          `Booking Allocated: Comp: (${comp.name} ${moment(comp.date).format("DD/MM/YY")}) Booking: (${user.firstName} ${user.lastName})`
        );
      });
  }

  async function confirmBooking(bkId, compId, uid, val) {
    const comp = await db
      .collection("ff_competitions")
      .doc(compId)
      .get()
      .then((doc) => ({
        ref: doc.ref,
        ...doc.data(),
      }));
    const booking = await db
      .collection("ff_bookings")
      .doc(bkId)
      .get()
      .then((doc) => ({
        ref: doc.ref,
        ...doc.data(),
      }));
    const user = await db
      .collection("users")
      .doc(uid)
      .get()
      .then((doc) => ({
        ref: doc.ref,
        ...doc.data(),
      }));
    booking.ref.update({
      confirmed: val,
    });
    if (val) {
      console.log(val)
      comp.ref.update({
        confirmedEntries: FieldValue.increment(+1),
      });
      await notification(
        "MyComp",
        `Your booking has been confirmed for ${comp.name} on ${moment(
          comp.date.toDate()
          ).format("DD/MM/YYYY")}`,
          "Booking Confirmed",
          `{"compId": ${compId}}`,
          user.ref
          );
          await logs(
            currentUser,
            `Booking Confirmed: Comp: (${comp.name} ${moment(comp.date).format("DD/MM/YY")}) Booking: (${user.firstName} ${user.lastName})`
            );
          } else {
      console.log(val)
      comp.ref.update({
        confirmedEntries: FieldValue.increment(-1),
      });
      await notification(
        "MyComp",
        `Your booking has been unconfirmed for ${comp.name} on ${moment(
          comp.date.toDate()
        ).format("DD/MM/YYYY")}`,
        "Booking Unconfirmed",
        `{"compId": ${compId}}`,
        user.ref
      );
      await userNotification(user.ref, `Your booking has been confirmed for ${comp.name} on ${comp.date}`, 'Booking Confirmed')
      await logs(
        currentUser,
        `Booking Unconfirmed: Comp: (${comp.name} ${moment(comp.date).format("DD/MM/YY")}) Booking: (${user.firstName} ${user.lastName})`
      );
    }
  }

  async function confirmAllBookings(bookings, compId) {
    const comp = await db
      .collection("ff_competitions")
      .doc(compId)
      .get()
      .then((doc) => ({
        ref: doc.ref,
        ...doc.data(),
      }));
    let counter = 0;
    for (let i = 0; i < bookings.length; i++) {
      const bk = bookings[i];
      console.log(bk);
      bk.ref.update({
        confirmed: true,
      });
      counter = i + 1;
      notification(
        "MyComp",
        `Your booking has been confirmed for ${comp.name} on ${moment(
          comp.date.toDate()
        ).format("DD/MM/YYYY")}`,
        "Booking Confirmed",
        `{"compId": ${comp.id}}`,
        bk.userRef
      );
      await userNotification(bk.userRef, `Your booking has been confirmed for ${comp.name} on ${comp.date}`, 'Booking Confirmed')
    }
    comp.ref.update({
      confirmedEntries: counter,
    });
    await logs(currentUser, `All Bookings Comfirmed: Comp: ${comp.name} ${moment(comp.date).format("DD/MM/YY")}`);
  }

  async function updateCompData(field, data, compId) {
    console.log(field ,data)
    const comp = await db
      .collection("ff_competitions")
      .doc(compId)
      .get()
      .then((doc) => ({
        ref: doc.ref,
        ...doc.data(),
      }));

    if (field === "pegs") {
      comp.ref.update({
        [field]: data,
        availablePegs: data - comp.anglers.length,
      });
    } else
    if ((field === "type" && data === "Paris") || data === "Teams") {
      comp.ref.update({
        [field]: data,
        pegs: data === "Paris" ? 2 : data === "Teams" ? 4 : 0,
        availablePegs: data === "Paris" ? 2 : data === "Teams" ? 4 : 0,
        zones: data === "Paris" ? 1 : data === "Teams" ? 1 : 1,
      });
    } else {
      comp.ref.update({
        [field]: data,
      });
    }
    await notification(
      "CompetitionsPage",
      "Your competition's details has been updated",
      "Competition's Details Updated",
      "{}",
      comp.hostRef
    );
    await logs(currentUser, `Competition Updated: ${comp.name} ${moment(comp.date).format("DD/MM/YY")}`);
  }

  async function updateUserData(uid, data) {
    const user = await db
      .collection("users")
      .doc(uid)
      .get()
      .then((doc) => ({
        ref: doc.ref,
        ...doc.data(),
      }));

    user.ref
      .update({
        ...data,
      })
      .then(() => {
        notification(
          "AccountPage",
          "Your account details has been updated",
          "Account Details Updated",
          `{}`,
          user.ref
        );
        logs(currentUser, `User Updated: ${user.firstName} ${user.lastName}`);
      });
    await userNotification(user.ref, "Your account details has been updated", "Account Details Updated")
  }

  async function updateSponsor(id, url) {
    const sponsor = await db
      .collection("sponsors")
      .doc(id)
      .get()
      .then((doc) => ({
        ref: doc.ref,
        ...doc.data(),
      }));
    sponsor.ref.update({
      logoUrl: url,
    });

    await logs(currentUser, `Sponsor Updated: ${sponsor.name}`);

    await db
      .collection("users")
      .where("sponsorId", "==", id)
      .get()
      .then((docs) => {
        docs.forEach((doc) => {
          db.collection("users").doc(doc.id).update({
            sponsorLogo: url,
          });
        });
      });
  }

  async function closeComp(compId) {
    const comp = await db
      .collection("ff_competitions")
      .doc(compId)
      .get()
      .then((doc) => ({
        ref: doc.ref,
        ...doc.data(),
      }));

    comp.ref
      .update({
        availablePegs: 0,
      })
      .then(() => {
        logs(currentUser, `Competiton Closed: ${comp.name} ${moment(comp.date).format("DD/MM/YY")}`);
      });
  }

  // --------------------------------------- notifications --------------------------------------- //

  // --------- Host Notifications --------- //
  function GetHostNoti() {
    const [notifications, setNotifications] = useState([]);
    useEffect(() => {
      const unsubscribe = db
        .collection("host_notification")
        .where('seen', '==', false)
        .where("hostId", "==", currentUser.uid)
        .orderBy("timestamp", "desc")
        .onSnapshot((snapshot) => {
          const notifList = snapshot.docs.map((doc) => ({
            ref: doc.ref,
            ...doc.data(),
          }));
          setNotifications(notifList);
        });
      return unsubscribe;
    }, []);
    return notifications;
  }

  function GetAllHostNoti() {
    const [notifications, setNotifications] = useState([]);
    useEffect(() => {
      const unsubscribe = db
        .collection("host_notification")
        .where("hostId", "==", currentUser.uid)
        .orderBy("timestamp", "desc")
        .onSnapshot((snapshot) => {
          const notifList = snapshot.docs.map((doc) => ({
            ref: doc.ref,
            ...doc.data(),
          }));
          setNotifications(notifList);
        });
      return unsubscribe;
    }, []);
    return notifications;
  }

  async function GetHostNotiCount() {
    return await db.collection('host_notification')
    .where("hostId", "==", currentUser.uid)
    .where('seen', '==', false)
    .get().then(snap => {return snap.docs.length})
  }

  function GetNotifications(userRef) {
    const [notifications, setNotifications] = useState([]);
    useEffect(() => {
      const unsubscribe = db
        .collection("notifications")
        .orderBy("timestamp", "desc")
        .where("userRef", "==", userRef)
        .limit(50)
        .onSnapshot((snapshot) => {
          const notifList = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setNotifications(notifList);
        });
      return unsubscribe;
    }, [userRef]);
    console.log("noti",notifications, userRef)
    return notifications;
  }

  async function userDisabled(user, reason) {
    if (reason) {
      user.ref.update({reason: reason})
      notification(
        "Home",
        `Admin Disabled Account: ${reason}`,
        "Account Disabled",
        " ",
        user.ref
      );
      logs(
        currentUser,
        `Admin Disabled Account: Reason: ${reason} User: ${user.firstName} ${user.lastName}`
      );
    }
  }

  async function userAllowed(user) {
      notification(
        "Home",
        `Admin Enabled Account.`,
        "Account Enabled",
        " ",
        user.ref
      );
      logs(
        currentUser,
        `Admin Enabled Account: User: ${user.firstName} ${user.lastName}`
      );
  }

  async function notification(
    initial_page_name,
    msg,
    title,
    parameter_data,
    userRef
  ) {
    const user = await userRef.get().then((doc) => ({
      ref: doc.ref,
      ...doc.data(),
    }));
      await user.ref.collection('user_notification').add({
        seen: false,
        message: msg,
        title,
        timestamp: new Date(),
      })
      await db.collection("notifications").add({
        initial_page_name,
        msg,
        title,
        parameter_data,
        userRef,
        timestamp: new Date(),
      });
    console.log("Notification created");
  }

  async function userNotification(userRef, message, title) {
    await userRef.collection("user_notification").add({
      seen: false,
      title: title,
      message: message,
      timestamp: new Date()
    })
  }

  async function logs(user, event) {
    await db.collection("ff_logs").add({
      ...user,
      event,
      timestamp: new Date(),
    });
  }

  const value = {
    // --- Set / Del --- //
    requestImportResults,
    requestImportAnglers,
    requestImportBookings,
    createComp,
    bookUser,
    deleteComp,
    deleteUser,
    setMarshall,
    addPmNumber,
    addSponsor,
    deleteSponsor,
    deleteResult,
    logResults,
    logBuyInResults,
    publishResults,
    // --- Get --- //
    GetUsers,
    GetAnglers,
    GetMarshalls,
    GetHosts,
    GetHostsDropDown,
    GetPmNumbers,
    GetSponsors,
    getSponsor,
    GetComps,
    GetArchivedComps,
    GetHostComps,
    GetArchivedHostComps,
    getTotalHostComps,
    GetRanking,
    getComp,
    getHost,
    getMarshallsPerComp,
    GetBookings,
    deleteBooking,
    GetBookingsDropdown,
    GetTeams,
    getTeamResults,
    getUser,
    GetResults,
    getResult,
    GetPegs,
    GetZones,
    GetLogs,
    getAnglerDetails,
    getMaxPmType,
    getRangkingshttps,
    getRangkingshttps2023,
    getRangkingshttps2022,
    // --- Update --- //
    updateAllCatches,
    updateAllUsers,
    updateAllNotifications,
    updateAllComps,
    updateBooking,
    confirmBooking,
    confirmAllBookings,
    updateCompData,
    updateUserData,
    updateSponsor,
    closeComp,
    // --- notifications --- //
    GetHostNoti,
    GetAllHostNoti,
    GetHostNotiCount,
    GetNotifications,
    userDisabled,
    userAllowed,
    notification,
    // Suport Tickets
    createTicket,
    updateTicketStatus,
    GetSupportTickets,
    getSupportTicket,
    deleteSupportTicket
  };

  return (
    <DatabaseContext.Provider value={value}>
      {children}
    </DatabaseContext.Provider>
  );
}
