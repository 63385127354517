import { Button, Card, CardActions, CardContent, Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useDb } from '../../contexts/DatabaseContext';
import TicketDetail from './TicketDetail';
import moment from 'moment';

const TaskCard = ({ title, description, label, id, date, user }) => {
  const [ticketId, setTicketId] = useState('')
  const [open, setOpen] = useState(false)
  
  const handleClose = () => {
    setOpen(false)
    setTicketId('')
  }

  const handleOpen = (ticketId) => {
    setTicketId(ticketId)
    setOpen(true)
  }
  return (
    <div>
      <Card onClick={() => handleOpen(id)} style={{width: '100%'}} elevation={0}>
        <CardContent>
          <h3>{title}</h3>
          <span className={label}>{label}</span>
          <p>{description}</p>
          <p>{user.firstName} {user.lastName}</p>
          <span className='pm-right'>{moment(new Date(date)).fromNow()}</span>
        </CardContent>
      </Card>
      {
        ticketId.length > 0
        ?
        <TicketDetail open={open} handleClose={() => handleClose()} ticketId={ticketId} />
        :
        null
      }
    </div>
  );
};

const TaskList = ({ tasks, id, title }) => {
  return (
    <div className="task-list">
      <h2>{title}</h2>
      <Droppable droppableId={id}>
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {tasks.map((task, index) => (
              <Draggable key={task.id} draggableId={task.id} index={index}>
                {(provided) => (
                  <div
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                  >
                    <TaskCard {...task} />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  );
};

const TaskView = () => {
  const { GetSupportTickets, updateTicketStatus } = useDb()
  const [tasks, setTasks] = useState({
    todo: [],
    inProgress: [],
    done: [],
  });

  useEffect(async () => {
    const todo = await GetSupportTickets('todo')
    const inProgress = await GetSupportTickets('inProgress')
    const done = await GetSupportTickets('done')
    setTasks({
      todo: todo,
      inProgress: inProgress,
      done: done
    })
  }, [])

  const handleDragEnd = (result) => {
    console.log(result)
    if (!result.destination) return;
  
    const { source, destination } = result;
  
    // Reordering within the same column
    if (source.droppableId === destination.droppableId) {
      setTasks((prevTasks) => {
        const columnId = source.droppableId;
        const newTaskIds = Array.from(prevTasks[columnId]);
        const [removed] = newTaskIds.splice(source.index, 1);
        newTaskIds.splice(destination.index, 0, removed);
  
        return {
          ...prevTasks,
          [columnId]: newTaskIds,
        };
      });
    }
    // Moving to a different column
    else {
      setTasks((prevTasks) => {
        const sourceColumnId = source.droppableId;
        const destinationColumnId = destination.droppableId;
  
        const sourceTaskIds = Array.from(prevTasks[sourceColumnId]);
        const [removed] = sourceTaskIds.splice(source.index, 1);
  
        const destinationTaskIds = Array.from(prevTasks[destinationColumnId]);
        destinationTaskIds.splice(destination.index, 0, removed);
  
        return {
          ...prevTasks,
          [sourceColumnId]: sourceTaskIds,
          [destinationColumnId]: destinationTaskIds,
        };
      });
      updateTicketStatus(result.draggableId, result.destination.droppableId)
    }
  };

  

  return (
    <div>
      <DragDropContext onDragEnd={handleDragEnd}>
        <div className="task-view">
          <Grid container spacing={3}>  
            <Grid item xs={12} md={4}>
              <TaskList tasks={tasks.todo} id='todo' title="To Do" />
            </Grid>
            <Grid item xs={12} md={4}>
              <TaskList tasks={tasks.inProgress} id='inProgress' title="In Progress" />
            </Grid>
            <Grid item xs={12} md={4}>
              <TaskList tasks={tasks.done} id='done' title="Done" />
            </Grid>
          </Grid>
        </div>
      </DragDropContext>
    </div>
  );
};

export default TaskView;