import { Button, CircularProgress, Dialog, DialogActions, DialogContent, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useDb } from '../../contexts/DatabaseContext'
import FileUploader from '../../shared/FileUploader'

export default function EditSponsor({open, sponsor, handleClose}) {
  const { updateSponsor, getSponsor } = useDb()
  const [uploadOpen, setUploadOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [newSponsor, setNewSponsor] = useState({...sponsor})
  
  console.log(sponsor)

  const getUrl = (sponsorUrl) => {
    setNewSponsor({
      ...sponsor,
      logoUrl: sponsorUrl
    })
    setLoading(false)
  }
  
  const close = () => {
    handleClose()
  }

  const handleSubmit = async () => {
    setLoading(true)
    try {
      await updateSponsor(newSponsor.id, newSponsor.logoUrl)
      close()
    }
    catch(err) {
      setError(err.message)
      setLoading(false)
    }
    
  }

  const handleFileSubmit = () => {
    setUploadOpen(false)
    setLoading(false)
  }

  const handleUploadOpen = () => {
    setUploadOpen(true)
    setLoading(true)
  }

  return (
    <div>
      <Dialog maxWidth="sm" fullWidth open={open} onClose={close}>
        <DialogContent>
          <Typography variant={"h4"}>{sponsor.name}</Typography>
          <br />
          <Typography>Sponsor Logo:</Typography>
          <br />          
          <img src={newSponsor.logoUrl} width="120" alt="Sponsor Logo"/>
          <br />
          <br />
          <Button onClick={() => handleUploadOpen()} variant={"contained"} color={"primary"}>Change</Button>
        </DialogContent>
        <DialogActions>
          <Button variant={"outlined"} color={"primary"} onClick={handleSubmit}>Save</Button>
          <Button variant={"outlined"} color={"secondary"} onClick={close}>Close</Button>
        </DialogActions>
      </Dialog>
      {/* File Uploader */}
      <Dialog maxWidth="sm" fullWidth open={uploadOpen} onClose={() => setUploadOpen(false)}>
        <DialogContent>
          <FileUploader getUrl={getUrl}/>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleFileSubmit} disabled={loading ? true : false || newSponsor.logoUrl === null ? true : false } color="primary">Confirm</Button>
          <Button onClick={() => setUploadOpen(false)} color="secondary">Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
