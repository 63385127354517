import React from 'react'
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, TextField } from '@material-ui/core'
import { useState } from 'react'
import { useDb } from '../../contexts/DatabaseContext'
import { useEffect } from 'react'

export default function AddPmNumbers({ open, handleClose }) {

  const { addPmNumber } = useDb()
  const [num, setNum] = useState(0)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setNum(null)
    setError(null)
    setLoading(false)
  }, [open])

  const handleSave = async (e) => {
    e.preventDefault()
    try {
      await addPmNumber(num)
      handleClose()
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div>
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth >
        <form onSubmit={(e) => handleSave(e)}>
          <DialogContent>
            {
              loading
              ?
              <center><CircularProgress /></center>
              :
              <TextField
                label="Pm Number"
                type="number"
                name="num"
                margin="normal"
                autoFocus
                onChange={(e) => setNum(e.target.value)}
                variant="outlined"
                fullWidth
                required
              />
            }
            {error ? <span style={{color: 'red'}}>{error}</span> : null}
          </DialogContent>
          <DialogActions>
            <Button color='primary' variant='contained' type='submit'>Save</Button>
            <Button color='secondary' variant='outlined' onClick={handleClose}>Cancel</Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  )
}
