import {
  Button,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import Container from "@material-ui/core/Container";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { version } from "../shared/consants";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  title: {
    margin: "10px 0",
  },
  btn: {
    margin: "15px 0",
  },
}));

export default function SignIn() {
  const classes = useStyles();
  const { logIn } = useAuth();

  const [showPassword, setShowPassword] = useState(false);

  const [state, setState] = useState({
    error: null,
    loading: false,
    data: {},
  });

  const handleChange = (e) => {
    setState({
      ...state,
      data: {
        ...state.data,
        [e.target.name]: e.target.value,
      },
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setState({
      ...state,
      error: null,
      loading: true,
    });
    try {
      await logIn(state.data.email, state.data.password);
    } catch (err) {
      console.log(err.message);
      setState({
        ...state,
        error: err.message,
        loading: false,
      });
    }
  };

  return (
    <div>
      <Container maxWidth="xs">
        <div className={classes.paper}>
          <Typography className={classes.title}>Sign In</Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              label="Email"
              type="email"
              name="email"
              margin="normal"
              onChange={handleChange}
              variant="outlined"
              fullWidth
              required
            />
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <TextField
                label="Password"
                name="password"
                required
                variant="outlined"
                margin="normal"
                type={showPassword ? "text" : "password"}
                value={state.data.password}
                onChange={handleChange}
              />
              <Button onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </Button>
            </div>
            <Button
              color="primary"
              variant="contained"
              fullWidth
              className={classes.btn}
              size="large"
              type="submit"
              disabled={state.loading}
            >
              Submit
            </Button>
          </form>
          <Grid container>
            <Grid item xs>
              <Link to="/forgot">Forgot password?</Link>
            </Grid>
            <Grid item>
              <Link to="/signup">Don't have an account? Sign Up</Link>
            </Grid>
          </Grid>
        </div>
        {state.error && (
          <Typography color="secondary">{state.error}</Typography>
        )}
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <center>version: {version}</center>
      </Container>
    </div>
  );
}
