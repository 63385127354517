import { Card, CardContent, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDb } from '../../../contexts/DatabaseContext'

export default function MarshallList({update}) {

  const { compId } = useParams()
  const { getMarshallsPerComp } = useDb()
  const [marshalls, setMarshalls] = useState([])
  
  useEffect( async () => {
    const marshalls = await getMarshallsPerComp(compId)
    setMarshalls(marshalls)
    console.log(marshalls)
  }, [update])


  return (
    <div>
      <Card style={{borderRadius: 10}} elevation={3}>
        <CardContent>
          <Table>
            <TableHead>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Phone Number</TableCell>
            </TableHead>
            <TableBody>
              {
                marshalls && marshalls.map(m => (
                  <TableRow key={m.id}>
                    <TableCell>{`${m.firstName} ${m.lastName}`}</TableCell>
                    <TableCell>{m.email}</TableCell>
                    <TableCell>{m.phone_number}</TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </div>
  )
}
