import { Button } from '@material-ui/core'
import { ArrowBack, LineStyle, People } from '@material-ui/icons'
import React from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import TeamsList from './TeamsList'

export default function Teams() {

  const { compId } = useParams()
  const history = useHistory()

  return (
    <div>
      <h1>Teams</h1>
      {/* <Button className='pm-right' startIcon={<People />} variant='contained' color='primary' onClick={handleClick}>Add Team</Button> */}
      <Button startIcon={<ArrowBack />} variant='contained' color='primary' onClick={() => history.goBack()}>Back</Button>
      <br /><br /><br />
      <TeamsList />
      <br />
      <Link to={`/competitions/${compId}/teams/results`}>
        <Button startIcon={<LineStyle />} variant='contained' color='primary'>View Results</Button>
      </Link>
    </div>
  )
}
