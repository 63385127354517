import { AppBar, Box, Button, Card, CardActions, CardContent, CircularProgress, Grid, makeStyles, Tab, Table, TableBody, TableCell, TableHead, TableRow, Tabs, TextField, Typography } from '@material-ui/core';
import { Pagination } from '@material-ui/lab'
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { useAuth } from '../../contexts/AuthContext';
import { useDb } from '../../contexts/DatabaseContext';
import { elevation, pmTypes, selectItemsPerPage } from '../shared/consants';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  primary: theme.palette.primary,
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

export default function Rankings2022() {
  const classes = useStyles();
  const { getRangkingshttps2022 } = useDb()
  const [value, setValue] = useState(0);
  const [pmType, setPmType] = useState('NM');
  const [rankingsList, setRankingsList] = useState([])
  const [orgRankingsList, setOrgRankingsList] = useState([])
  const [rankings, setRankings] = useState([])
  const [loading, setLoading] = useState(true)
  const { currentUser } = useAuth()
  const windowDimensions = getWindowDimensions();

  const [search, setSearch] = useState('')
  let counter = 0;
  
  const handleChange = (e, newValue) => {
    setPage(1)
    setPmType(e.target.innerText)
    setValue(newValue);
  };

  useEffect(async () => {
    const newRankings = await getRangkingshttps2022()
    setRankings(newRankings)
    let tempArray = []
    newRankings.sort((a,b) => b.bmtCalc-a.bmtCalc).map((ang) => {
      if(ang.pmType === pmType) {
        counter ++
        tempArray.push({
          id: ang.id,
          pos: counter,
          pmNumber: `${ang.pmNumber}`,
          pmType: `${ang.pmType}`,
          display_name: ang.display_name,
          sponsorLogo: ang.sponsorLogo,
          count: ang.count,
          bmtCalc: ang.bmtCalc.toFixed(2)
        })
      }
    })
    setRankingsList(tempArray)
  }, [])

  useEffect( async () => {
    let tempArray = []
    rankings.sort((a,b) => b.bmtCalc-a.bmtCalc).map((ang) => {
      if(ang.pmType === pmType) {
        counter ++
        tempArray.push({
          id: ang.id,
          pos: counter,
          pmNumber: `${ang.pmNumber}`,
          pmType: `${ang.pmType}`,
          display_name: ang.display_name,
          sponsorLogo: ang.sponsorLogo,
          count: ang.count,
          bmtCalc: ang.bmtCalc.toFixed(2)
        })
      }
    })
    setRankingsList(tempArray)
    setOrgRankingsList(tempArray)
    setLoading(false)
  }, [pmType])
  
  useEffect(() => {
    let tempArray = []
    setPage(1)
    orgRankingsList.map(r => {
      if(
        r.display_name.toLowerCase().includes(search.toLowerCase()) ||
        r.pmNumber.toString().toLowerCase().includes(search.toLowerCase())
        ) {
          tempArray.push(r)
        }
      })
      setRankingsList(search ? tempArray : rankingsList)
    }, [search])
    
      // Pagination
      const [itemsPerPage, setItemsPerPage] = useState(20)
      const [page, setPage] = useState(1)
      const [noOfPages] = useState(
        Math.ceil((rankingsList).length / itemsPerPage)
      )
    
    return (
      <div className={classes.root}>
      {
        loading
        ?
        <CircularProgress />
        :
        <>
          <Card elevation={3} style={{backgroundColor: '#2788c8', color: '#fff', borderRadius: 10}}>
            <Tabs
              value={value}
              onChange={handleChange}
              style={{width: `${windowDimensions.width *0.82}px`}}
              indicatorColor="secondary"
              textColor="default"
              variant="scrollable"
              scrollButtons="on"
            >
              {
                pmTypes.map((pmType, index) => {  
                  return (
                    <Tab {...a11yProps(index)} label={pmType.label} id={pmType.value} />
                  )
                })
              }
            </Tabs>
          </Card>
          <br/>
          <Card elevation={3} style={{borderRadius: 10}}>
            <CardContent>
              <Grid container spacing={5}>
                <Grid item xs={12} sm={10}>
                  <TextField
                    label='Search'
                    type='text'
                    variant="outlined" 
                    fullWidth 
                    onChange={(e) => setSearch(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} sm={2}>
                  Items Per Page
                  <Select options={selectItemsPerPage} onChange={(val) => {
                    setItemsPerPage(val.value)
                    setPage(1)
                  }}/>
                </Grid>
              </Grid>
              <Table>
                  <TableHead>
                      <TableRow>
                          <TableCell>Pos</TableCell>
                          <TableCell>PM Type</TableCell>
                          <TableCell>PM No</TableCell>
                          <TableCell>Full Name</TableCell>
                          <TableCell>Sponsor</TableCell>
                          <TableCell>Total Comps</TableCell>
                          <TableCell>BMT</TableCell>
                          {
                            currentUser
                            ?
                            <TableCell>Details</TableCell>
                            :
                            null
                          }
                      </TableRow>
                  </TableHead>
                  <TableBody>
                      {
                        (rankingsList)
                        .slice((page -1) * itemsPerPage, page * itemsPerPage)
                        .map((ang) => {
                          return (
                            <TableRow key={ang.id}>
                              <TableCell>{ang.pos}</TableCell>
                              <TableCell>{`${ang.pmType}`}</TableCell>
                              <TableCell>{`${ang.pmNumber}`}</TableCell>
                              <TableCell>{ang.display_name}</TableCell>
                              <TableCell>{<img style={{height: 40}} src={ang.sponsorLogo} alt="Sponsor Logo"/>}</TableCell>
                              <TableCell>{ang.count}</TableCell>
                              <TableCell>{ang.bmtCalc}</TableCell>
                              {
                                currentUser 
                                ?
                                <TableCell>
                                  <Link to={`/angler-details/${ang.id}`}>
                                    <Button variant="contained" color="primary" >
                                      Details
                                    </Button>
                                  </Link>
                                </TableCell>
                                :
                                null 
                              }
                            </TableRow>
                          )
                        })
                      }
                  </TableBody>        
              </Table>
            </CardContent>
            <CardActions>
              <Pagination
                count={Math.ceil((search ? rankings : rankingsList).length / itemsPerPage)}
                page={page}
                onChange={(e, val) => setPage(val)}
                defaultPage={1}
                color="primary"
                size="large"
                showFirstButton
                showLastButton
              />  
            </CardActions>
          </Card>
        </>
      }
    </div>
  );
}
