import { Button, Card, CardActions, CardContent, CardHeader, CircularProgress, Dialog, DialogActions, DialogContent, MenuItem, Select as muiSelect, Switch, TextField, Typography } from '@material-ui/core'
import { ArrowBack, Cancel, Save } from '@material-ui/icons'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import { useDb } from '../../contexts/DatabaseContext'
import { elevation, pmTypes, roles, selectFieldProps } from '../shared/consants'
import Select from 'react-select';

export default function EditAnglers() {

  const history = useHistory()
  const { getUser, updateUserData, userDisabled, GetSponsors, GetPmNumbers, userAllowed } = useDb()
  const {uid} = useParams()
  const [user, setUser] = useState({})
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [open, setOpen] = useState(false)
  const [reason, setReason] = useState('')
  const sponsors = GetSponsors()
  const pmNumbers = GetPmNumbers().map(pm => ({value: pm.sort_id, label: `${pm.sort_id} - ${pm.status}`}))
  
  useEffect( async () => {
    setLoading(true)
    const user = await getUser(uid)
    setUser({
      ...user,
      allow_login: user.allow_login
    })
    setLoading(false)
  }, [])
  
  const sponsorOptions = sponsors.map((s) => ({
    label: s.name,
    value: s
  }))

  const handleChange = (e) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value
    })
  }

  const handleSelectChange = (val) => {
    setUser({
      ...user,
      role: val.value,
      admin: val.value === 'Admin' ? true : false
    })
  }

  const handleAllowLoginChange = (e) => {
    if (!e.target.checked) {
      setOpen(true)
    } else {
      userAllowed(user)
    }
    setUser({
      ...user,
      allow_login: e.target.checked
    })
  };

  const handleSponsorSelect = (val) => {
    setUser({
      ...user,
      sponsorId: val.sponsorId ? val.sponsorId : val.id,
      sponsorLogo: val.logoUrl,
      sponsorName: val.name
    })
  }

  const handlePmTypeSelect = (val) => {
    setUser({
      ...user,
      pmType: val
    })
  }

  const handlePmNumberSelect = (val) => {
    setUser({
      ...user,
      pmNumber: val
    })
  }

  const handleSave = async () => {
    setLoading(true)
    try {
      await updateUserData(uid, user)
      setLoading(false)
      history.goBack()
    } catch (error) {
      setError(error.message)
      setLoading(false)
    }
  }

  console.log(user)

  return (
    <div>
      {
        loading
        ?
        <CircularProgress />
        :
        <div>
          <h1>Edit Angler</h1>
          <Button startIcon={<ArrowBack />} variant='contained' color='primary' onClick={() => history.goBack()}>Back</Button>
          <br /><br />
          <Card elevation={3} style={{borderRadius: 10 }}>
            <CardHeader 
              action={
                <>
                  Allow User Login: 
                  <Switch 
                    checked={user.allow_login}
                    color="primary"
                    name="allow_login"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                    onChange={handleAllowLoginChange}
                  />
                </>
              }
            />
            <CardContent>
              <TextField
                label='Display Name'
                name='display_name'
                defaultValue={user.display_name}
                onChange={handleChange}
                variant="outlined" 
                fullWidth 
              />
              <br /><br />
              <TextField
                label='First Name'
                name='firstName'
                defaultValue={user.firstName}
                onChange={handleChange}
                variant="outlined" 
                fullWidth 
              />
              <br /><br />
              <TextField
                label='Last Name'
                name='lastName'
                defaultValue={user.lastName}
                onChange={handleChange}
                variant="outlined" 
                fullWidth 
              />
              <br /><br />
              <TextField
                label='Phone Number'
                name='phone_number'
                type='number'
                defaultValue={user.phone_number ? user.phone_number : user.phoneNumber}
                onChange={handleChange}
                variant="outlined" 
                fullWidth 
              />
              <br /><br />
              <TextField
                label='Email'
                name='email'
                type='email'
                defaultValue={user.email}
                onChange={handleChange}
                variant="outlined" 
                fullWidth 
              />
              <br /><br />
              {/* <TextField
                label='Email'
                name='email'
                type='email'
                defaultValue={user.email}
                onChange={handleChange}
                variant="outlined" 
                fullWidth 
              />
              <br /><br /> */}
              <TextField
                label='ID Number'
                name='idNumber'
                type='number'
                defaultValue={user.idNumber}
                onChange={handleChange}
                variant="outlined" 
                fullWidth 
              />
              <br /><br />
              <Typography>Pm Type: {user.pmType} </Typography>
              <Select
                onChange={(val) => handlePmTypeSelect(val.value)}
                {...selectFieldProps}
                placeholder="Select Pm Type"
                fullWidth
                options={pmTypes}
                variant="outlined" 
              ></Select>
              <Typography>Pm Number: {user.pmNumber} </Typography>
              <Select
                onChange={(val) => handlePmNumberSelect(val.value)}
                {...selectFieldProps}
                placeholder="Select Pm Number"
                fullWidth
                options={pmNumbers}
                variant="outlined" 
              ></Select>
              <Typography>Sponsor: {user.sponsorName} <br /> <img height={50} src={user.sponsorLogo} alt={user.sponsorName} /> </Typography>
              <Select
                onChange={(val) => handleSponsorSelect(val.value)}
                {...selectFieldProps}
                placeholder="Select Sponsor"
                fullWidth
                options={sponsorOptions}
                variant="outlined" 
              ></Select>
              <br /><br />
              <TextField
                label='City'
                style={{marginBottom: 10}}
                defaultValue={user.city}
                required 
                name='city'
                variant="outlined" 
                type='text'
                fullWidth 
                onChange={(e) => handleChange(e)}
              />
              <Typography>Role: {user.role}</Typography>
              <Select
                onChange={handleSelectChange}
                {...selectFieldProps}
                placeholder="Select Role"
                fullWidth
                variant="outlined" 
                options={roles}
              >
              </Select>
              <span>{error ? error : null}</span>
            </CardContent>
            <CardActions>
              <Button variant='contained' color='primary' onClick={handleSave} startIcon={<Save />}>Save</Button>
            </CardActions>
          </Card>
        </div>
      }
      <Dialog open={open} onClose={() => setOpen(false)} maxWidth="lg" fullWidth >
        <form onSubmit={(e) => {
          e.preventDefault()
          userDisabled(user, reason)
          setOpen(false)
        }}>
          <DialogContent>
            <TextField 
              label='Reason'
              type='text'
              variant="outlined" 
              required
              fullWidth 
              onChange={(e) => setReason(e.target.value)}
            /> 
          </DialogContent>
          <DialogActions>
            <Button
              variant='contained'
              type='submit'
              color='primary'
            >Confirm</Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  )
}
