import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Typography,
} from '@material-ui/core';
import { useAuth } from '../../contexts/AuthContext';
import Select from 'react-select';
import { categories, selectFieldProps, selectStyles } from './consants';
import { useDb } from '../../contexts/DatabaseContext';

function CreateTicket({ open, handleClose, path }) {
  const { createTicket } = useDb()
    const { currentUser } = useAuth()
  const [title, setTitle] = useState('');
  const [selectedCat, setSelectedCat] = useState('');
  const [description, setDescription] = useState('');

  const handleSubmit = (event) => {

    event.preventDefault();
    const newTicket = {
      title,
      description,
      status: 'todo',
      date: new Date().toLocaleString(),
      label: selectedCat,
      user: {...currentUser},
      path: path
    };

    createTicket(newTicket)
    setTitle('');
    setDescription('');
    handleClose();
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Create a new ticket</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter the details of your new support ticket below.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="title"
            label="Title"
            type="text"
            fullWidth
            value={title}
            onChange={(event) => setTitle(event.target.value)}
          />
          <TextField
            margin="dense"
            id="description"
            label="Description"
            type="text"
            fullWidth
            multiline
            rows={4}
            value={description}
            onChange={(event) => setDescription(event.target.value)}
          />
          <br />
          <br />
          <Typography>Select Category</Typography>
          <Select 
            {...selectFieldProps}
            defaultValue='Select Category'
            options={categories}
            onChange={(e) => {setSelectedCat(e.label)}}
          />
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose} variant='outlined' color='secondary'>Cancel</Button>
            <Button onClick={handleSubmit} variant='contained' color='primary'>Create Ticket</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default CreateTicket;
