import { Button, Card, CardContent, CardHeader, Dialog, DialogActions, DialogContent, Typography } from '@material-ui/core'
import React from 'react'
import { useState } from 'react'
import Select from 'react-select'
import { useDb } from '../../../contexts/DatabaseContext'

export default function AddMarshall({open, close, compId}) {

  const { GetMarshalls, setMarshall } = useDb()
  const marshalls = GetMarshalls()
  const [state, setState] = useState(null)
  const [error, setError] = useState(null)

  const marshallOptions = marshalls && marshalls.map(m => (
    {
      label: `${m.firstName} ${m.lastName}`,
      value: m.id
    }
  ))

  const handleSelect = (val) => {
    setState(val)
    console.log(state)
  }

  const handleClose = () => {
    close()
    setState(null)
  }

  const handleSave = async () => {
    const res = await setMarshall(compId, state) 
    if (res === null) {
      setError('Marshall Already added to the competition')
    } else {
      handleClose()
    }
  }

  return (
    <Dialog style={{padding: 20}} open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <CardHeader title={'Assign Marsalls to Copmetition'}></CardHeader>
      <DialogContent>
        <Select
          options={marshallOptions}
          menuPortalTarget={document.body}
          styles={{ menuPortal: base => ({ ...base, zIndex: 9999 })}}
          placeholder={'Add Marshall to comp'}
          onChange={(val) => handleSelect(val.value)}
        />
        {error ? <span style={{color: 'red'}} >{error}</span> : null}
      </DialogContent>
      <DialogActions>
        <Button variant='contained' color='primary' onClick={handleSave} >Save</Button>
        <Button variant='outlined' color='secondary' onClick={handleClose} >Cancel</Button>
      </DialogActions>
    </Dialog>
  )
}
