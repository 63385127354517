import { Card, CardContent, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Button, Tooltip, Popper, CardActions, TextField, Grid } from '@material-ui/core'
import { CancelOutlined, CheckCircle, Delete, Edit, ImportContacts, PersonAdd } from '@material-ui/icons'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import AddAngler from './AddAngler'
import { useDb } from '../../contexts/DatabaseContext'
import { Pagination } from '@material-ui/lab'
import { elevation, selectItemsPerPage } from '../shared/consants'
import Select from 'react-select'

export default function AnglersList() {

  const { GetUsers, deleteUser } = useDb()
  const users = GetUsers()
  const history = useHistory()
  const [open, setOpen] = useState()
  const [uid, setUid] = useState()
  const [anchorEl, setAnchorEl] = useState(null)
  const [search, setSearch] = useState(null)
  const { pager } = useParams()
  
  users.sort((a,b) => {return a.pmNumber - b.pmNumber})
  
  const [anglers, setAnglers] = useState(users && users)
  // Pagination
  const [itemsPerPage, setItemsPerPage] = useState(7)
  const [page, setPage] = useState(parseInt(pager) ? parseInt(pager) : 1)
  const [noOfPages] = useState(
    Math.ceil((search ? anglers : users).length / itemsPerPage)
  )

  useEffect(() => {
    let tempArray = []
    users.map(u => {
      if(
        // u.pmType.toLowerCase().includes(search.toLowerCase()) 
        u.pmNumber.toString().toLowerCase().includes(search.toLowerCase()) 
        || u.firstName.toLowerCase().includes(search.toLowerCase()) 
        || u.lastName.toLowerCase().includes(search.toLowerCase()) 
        || u.email.toLowerCase().includes(search.toLowerCase()) 
        // || u.role.toLowerCase().includes(search.toLowerCase()) 
        // (u.phone_number ? u.phone_number.toLowerCase().includes(search.toLowerCase()) : u.phoneNumber.toLowerCase().includes(search.toLowerCase()))
      ) {
        setPage(1)
        history.push('1')
        tempArray.push(u)
      }
    })
    setAnglers(tempArray)
  }, [search])

  console.log(users)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleClick = (e, uid) => {
    setUid(uid)
    setAnchorEl(anchorEl ? null : e.currentTarget);
  }

  const handleDelete = async (e, uid) => {
    try {
      await deleteUser(uid)
    } catch (err) {
      console.log(err)
    }
    setAnchorEl(anchorEl ? null : e.currentTarget)
  }

  const openAnchor = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  return (
    <>
      <Button onClick={() => history.push('/anglers/import')} startIcon={<ImportContacts />} variant='contained' className='pm-right' color='primary'>Import Anglers</Button>
      <Button style={{marginRight: 5}} onClick={handleOpen} startIcon={<PersonAdd />} variant='contained' className='pm-right' color='primary'>Add Angler</Button>
      <br />
      <br />
      <br />
      <Card elevation={elevation} style={{borderRadius: 10}}>
        <CardContent>
          <Grid container spacing={5}>
            <Grid item xs={12} sm={10}>
              <TextField
                label='Search'
                type='text'
                variant="outlined" 
                fullWidth 
                onChange={(e) => setSearch(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              Items Per Page
              <Select options={selectItemsPerPage} onChange={(val) => {
                  setItemsPerPage(val.value)
                  setPage(1)
                  history.push('1')
                }}/>
            </Grid>
          </Grid>
          <Table>
            <TableHead>
              <TableCell>Pm Type</TableCell>
              <TableCell>Pm Number</TableCell>
              <TableCell>First Name</TableCell>
              <TableCell>Last Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Phone Number</TableCell>
              <TableCell>Role</TableCell>
              <TableCell>Sponsor</TableCell>
              <TableCell></TableCell>
              <TableCell>Login</TableCell>
              <TableCell>Actions</TableCell>
            </TableHead>
            <TableBody>
              {
                (search ? anglers : users)
                .slice((page -1) * itemsPerPage, page * itemsPerPage)
                .map(u => {
                  return(
                    <TableRow key={u.uid}>
                      <TableCell>{u.pmType}</TableCell>
                      <TableCell>{u.pmNumber}</TableCell>
                      <TableCell>{u.firstName}</TableCell>
                      <TableCell>{u.lastName}</TableCell>
                      <TableCell>{u.email}</TableCell>
                      <TableCell>{u.phone_number ? u.phone_number : u.phoneNumber}</TableCell>
                      <TableCell>{u.role}</TableCell>
                      <TableCell>{u.sponsorName}</TableCell>
                      <TableCell><img src={u.sponsorLogo} alt={u.sponsorName} style={{height: 40}} /></TableCell>
                      {
                        u.allow_login == false
                        ?
                        <TableCell><CancelOutlined style={{color: '#fa0526'}} color='#fa0526' /></TableCell>
                        :
                        <TableCell><CheckCircle style={{color: '#4ceb34'}} color='#4ceb34' /></TableCell>
                      }
                      <TableCell>
                        <Tooltip arrow title='Edit'>
                          <IconButton
                            onClick={() => history.push(`${u.uid}/edit`)}
                          >
                            <Edit />
                          </IconButton>
                        </Tooltip>
                        <Tooltip arrow title='Delete'>
                          <IconButton
                            aria-describedby={u.uid}
                            onClick={(e) => handleClick(e, u.uid)}
                          >
                            <Delete />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  )
                })
              }
            </TableBody>
          </Table>
        </CardContent>
        <CardActions>
          <Pagination
            count={Math.ceil((search ? anglers : users).length / itemsPerPage)}
            page={page}
            onChange={(e, val) => {
              history.push(`${val}`)
              setPage(val)
            }}
            defaultPage={parseInt(pager)}
            color="primary"
            size="large"
            showFirstButton
            showLastButton
          />  
        </CardActions>
      </Card>
      <Popper placement='left' id={id} open={openAnchor} anchorEl={anchorEl} >
        <Card elevation={elevation}>
          <CardContent>
            <center><h2>Are You Sure</h2></center>
            <p style={{fontSize: 18}}><b>This Action Cannot be undone</b></p>
          </CardContent>
          <CardActions>
            <Button variant='contained' color='primary' onClick={(e) => {
              handleDelete(e, uid)
              }}>Yes, Delete</Button>
            <Button variant='outlined' color='secondary' onClick={handleClick}>Cancel</Button>
          </CardActions>
        </Card>
      </Popper>
      <AddAngler open={open} close={handleClose} />
    </>
  )
}
