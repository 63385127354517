import React from 'react';
import AnglersList from './AnglersList';

export default function Anglers() {
  return (
  <div>
    <h1>Anglers</h1>
    <AnglersList />
  </div>)
}
