import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  TextField,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  DatePicker,
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import React, { useState } from "react";
import Select from "react-select";
import { compTypes, year } from "../shared/consants";
import { useDb } from "../../contexts/DatabaseContext";
import { useAuth } from "../../contexts/AuthContext";
import moment from "moment";

export default function NewComp({ open, handleClose }) {
  const { createComp, GetHosts } = useDb();
  const { currentUser } = useAuth();
  const [name, setName] = useState("");
  const [notes, setNotes] = useState("");
  const [venue, setVenue] = useState("");
  const [host, setHost] = useState("");
  const [type, setType] = useState("");
  const [gateFee, setGateFee] = useState("");
  const [entryFee, setEntryFee] = useState("");
  const [noPotFee, setNoPotFee] = useState("");
  const [nonMemberFee, setNonMemberFee] = useState(150);
  const [pegs, setPegs] = useState("");
  const [zones, setZones] = useState(1);
  const [teamMax, setTeamMax] = useState(1);
  const [date, setDate] = useState(Date().toString());
  const [startTime, setStartTime] = useState(new Date(year, 1, 1, 5));
  const [endTime, setEndTime] = useState(new Date(year, 1, 1, 6));
  const [anglingStart, setAnglingStart] = useState(new Date(year, 1, 1, 7));
  const [anglingEnd, setAnglingEnd] = useState(new Date(year, 1, 1, 15));
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const hosts = GetHosts();
  console.log(hosts);

  const hostsOptions =
    hosts &&
    hosts.map((m) => ({
      value: m.id ? m.id : m.uid,
      label: `${m.firstName} ${m.lastName} (${m.email})`,
    }));

  const handleSubmit = async () => {
    console.log(type);
    setLoading(true);
    setError(null);
    try {
      if (type.value.includes("Costal") && pegs < 5) {
        setError("Costal competitions must have at least 5 pegs");
        setLoading(false);
      } else {
        if (type.value.includes("Inland") && pegs < 8) {
          setError("Inland competitions must have at least 8 pegs");
          setLoading(false);
        } else {
          if (
            name &&
            venue &&
            type &&
            gateFee &&
            entryFee &&
            noPotFee &&
            nonMemberFee &&
            notes
          ) {
            await createComp(
              {
                name,
                venue,
                type: type.value,
                gateFee: parseInt(gateFee),
                entryFee: parseInt(entryFee),
                date: typeof date === "string" ? moment(date).toDate() : date,
                startTime,
                endTime,
                anglingStart,
                anglingEnd,
                pegs: parseInt(pegs),
                noPotFee: parseInt(noPotFee),
                nonMemberFee: parseInt(nonMemberFee),
                notes,
                // teamMax: parseInt(teamMax),
                host: host ? host : currentUser.uid,
              },
              parseInt(zones)
            ).then(() => {
              setLoading(false);
              handleClose();
            });
          } else {
            setError("All the fields must be filled in");
            setLoading(false);
          }
        }
      }
    } catch (err) {
      console.log(err.message);
      setError(err.message);
      setLoading(false);
    }
  };

  const spacing = 10;

  console.log(type);

  return (
    <>
      {loading ? (
        <CircularProgress />
      ) : (
        <div>
          <Dialog
            open={open}
            onClose={() => {
              handleClose();
              setError(null);
            }}
            maxWidth="md"
            fullWidth
          >
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <span style={{ color: "red" }}>{error ? error : null}</span>
                <TextField
                  style={{ marginBottom: spacing }}
                  required
                  variant="outlined"
                  fullWidth
                  label="Competition Name"
                  onChange={(e) => setName(e.target.value)}
                />
                <TextField
                  style={{ marginBottom: spacing }}
                  required
                  variant="outlined"
                  fullWidth
                  label="Place/Venue"
                  onChange={(e) => setVenue(e.target.value)}
                />
                <TextField
                  style={{ marginBottom: spacing }}
                  required
                  variant="outlined"
                  fullWidth
                  label="Notes"
                  onChange={(e) => setNotes(e.target.value)}
                  multiline
                  rows={3}
                />
                <Select
                  options={hostsOptions}
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                  placeholder={"Organizer (Main Host Account)"}
                  onChange={(val) => {
                    console.log(val);
                    setHost(val.value);
                  }}
                />
                <br />
                <Grid container spacing={3}>
                  <Grid item md={6}>
                    <Select
                      options={compTypes}
                      onChange={(val) => setType(val)}
                      placeholder="Competition Type"
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                    />
                    <br />
                    <TextField
                      style={{ marginBottom: spacing }}
                      type="number"
                      required
                      variant="outlined"
                      fullWidth
                      label="Gate Fee"
                      onChange={(e) => setGateFee(e.target.value)}
                    />
                    <TextField
                      style={{ marginBottom: spacing }}
                      type="number"
                      required
                      variant="outlined"
                      fullWidth
                      label="Full Pool Fee"
                      onChange={(e) => setEntryFee(e.target.value)}
                    />
                    <TextField
                      style={{ marginBottom: spacing }}
                      type="number"
                      required
                      variant="outlined"
                      fullWidth
                      label="No Pool Fee"
                      onChange={(e) => setNoPotFee(e.target.value)}
                    />
                    <TextField
                      style={{ marginBottom: spacing }}
                      type="number"
                      required
                      variant="outlined"
                      defaultValue={150}
                      fullWidth
                      label="Non Member Fee"
                      onChange={(e) => setNonMemberFee(e.target.value)}
                    />
                    <TextField
                      style={{ marginBottom: spacing }}
                      InputProps={{
                        inputProps: { max: 100, min: 5 },
                      }}
                      required
                      fullWidth
                      type="number"
                      label="Pegs"
                      variant="outlined"
                      onChange={(e) => setPegs(e.currentTarget.value)}
                    />
                    <TextField
                      style={{ marginBottom: spacing }}
                      required
                      fullWidth
                      type="number"
                      label="Zones"
                      variant="outlined"
                      onChange={(e) => setZones(e.currentTarget.value)}
                    />
                  </Grid>
                  <Grid item md={6}>
                    {type.value === "Teams" ? (
                      <TextField
                        style={{ marginBottom: spacing }}
                        required
                        fullWidth
                        type="number"
                        label="Team Max"
                        variant="outlined"
                        onChange={(e) => setTeamMax(e.currentTarget.value)}
                      />
                    ) : null}
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <>
                        <DatePicker
                          style={{ marginBottom: spacing }}
                          label="Competition Date"
                          fullWidth
                          inputVariant="outlined"
                          format="dd/MM/yyyy"
                          value={date}
                          onChange={setDate}
                        />
                      </>
                    </MuiPickersUtilsProvider>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardTimePicker
                        style={{ marginBottom: spacing }}
                        ampm={false}
                        fullWidth
                        variant="inline"
                        label="Entry Start Time"
                        value={startTime}
                        onChange={(t) => {
                          setStartTime(t);
                        }}
                        inputVariant="outlined"
                      />
                    </MuiPickersUtilsProvider>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardTimePicker
                        style={{ marginBottom: spacing }}
                        ampm={false}
                        fullWidth
                        variant="inline"
                        label="Entry End Time"
                        value={endTime}
                        onChange={(t) => setEndTime(t)}
                        inputVariant="outlined"
                      />
                    </MuiPickersUtilsProvider>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardTimePicker
                        style={{ marginBottom: spacing }}
                        ampm={false}
                        fullWidth
                        variant="inline"
                        label="Angling Start Time"
                        value={anglingStart}
                        onChange={(t) => setAnglingStart(t)}
                        inputVariant="outlined"
                      />
                    </MuiPickersUtilsProvider>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardTimePicker
                        style={{ marginBottom: spacing }}
                        ampm={false}
                        fullWidth
                        variant="inline"
                        label="Angling End Time"
                        value={anglingEnd}
                        onChange={(t) => setAnglingEnd(t)}
                        inputVariant="outlined"
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    handleClose();
                    setError(null);
                  }}
                >
                  Cancel
                </Button>
                <Button variant="contained" color="primary" type="submit">
                  Create
                </Button>
              </DialogActions>
            </form>
          </Dialog>
        </div>
      )}
    </>
  );
}
