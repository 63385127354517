import React from 'react'
import LogList from './LogList'

export default function Logs() {
  return (
    <div>
      <h1>Logs</h1>
      <LogList />
    </div>
  )
}
