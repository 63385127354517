import { Button, Card, CardActions, CardContent, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@material-ui/core'
import { Delete, Edit } from '@material-ui/icons'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { useAuth } from '../../../contexts/AuthContext'
import { useDb } from '../../../contexts/DatabaseContext'
import { elevation } from '../../shared/consants'

export default function ResultList({ results }) {

  const { compId } = useParams()
  const { currentUser } = useAuth()
  const { publishResults, getComp, getUser, deleteResult } = useDb()
  const [comp, setComp] = useState({})
  const [newResults, setNewResults] = useState([])
  const [updated, setUpdated] = useState(0)
  const [trigger, setTrigger] = useState(true)
  const history = useHistory()
  useEffect( async () => {
    const c = await getComp(compId)
    setComp(c)
  }, [trigger])

  console.log(results)
  useEffect(async () => {
    const newR = await results.map(async r => {
      const user = await getUser(r.uid)
      return {
        pmType: user.pmType,
        pmNumber: user.pmNumber,
        sponsor: user.sponsorName,
        sponsorLogo: user.sponsorLogo,
        ...r,
      }
    })
    setNewResults(await Promise.all(newR))
  }, [updated])

  const triggerPageLoad = () => {
    setTrigger(!trigger)
  }
  
  const handlePublish = (bool) => {
    publishResults(compId, bool)
    triggerPageLoad()
  }
  
  const handleDelete = (res) => {
    deleteResult(res)
    .then(() => {
      setUpdated(updated + 1)
    })
  }
  console.log(newResults)
  
  return (
    <div>
      <Card elevation={elevation} style={{borderRadius: 10}}>
        <CardContent>
          <Table>
            <TableHead>
              <TableCell>Pos</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>PM Number</TableCell>
              <TableCell>Sponsor</TableCell>
              <TableCell></TableCell>
              <TableCell>BMT</TableCell>
              <TableCell>Score</TableCell>
              <TableCell>Weight</TableCell>
              <TableCell>Fish</TableCell>
              <TableCell>Winnings</TableCell>
              <TableCell>Peg</TableCell>
              <TableCell>Zone</TableCell>
              {
                currentUser.role === 'Admin'
                ?
                <TableCell>Actions</TableCell>
                :
                null
              }
            </TableHead>
            <TableBody>
              {
                newResults && newResults.map(r => {
                  return (
                    <TableRow key={r.uid}>
                        <TableCell>{r.pos}</TableCell>
                        <TableCell>{r.display_name}</TableCell>
                        <TableCell>{`${r.pmType} ${r.pmNumber}`}</TableCell>
                        <TableCell>{r.sponsor}</TableCell>
                        <TableCell><img style={{height: 40}} src={r.sponsorLogo} alt="" /></TableCell>
                        <TableCell>{typeof r.bmt == typeof " " ? parseFloat(r.bmt).toFixed(2) : r.bmt.toFixed(2)}</TableCell>
                        <TableCell>{typeof r.score == typeof " " ? parseFloat(r.score).toFixed(2) : r.score.toFixed(2)}</TableCell>
                        <TableCell>{typeof r.totalWeight == typeof " " ? parseFloat(r.totalWeight).toFixed(2) : r.totalWeight.toFixed(2)}</TableCell>
                        <TableCell>{r.totalFish}</TableCell>
                        <TableCell>{`R${r.winnings ? r.winnings : 0}`}</TableCell>
                        <TableCell>{r.peg}</TableCell>
                        <TableCell>{r.zone}</TableCell>
                        {
                          currentUser.role == 'Admin'
                          ?
                          <TableCell>
                            <Tooltip arrow title='Delete'>
                              <IconButton onClick={() => handleDelete(r)}>
                                <Delete />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title='Edit'>
                              <IconButton onClick={() => history.push(`/competitions/${compId}/result/${r.id}/edit`)}>
                                <Edit />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        :
                        null
                        }
                    </TableRow>
                  )
                })
              }
            </TableBody>
          </Table>
        </CardContent>
        <CardActions>
          <Button disabled={comp.published || results.length < 1} variant='contained' color='primary' onClick={() => handlePublish(true)}>Publish Results</Button>
          <Button disabled={!comp.published} variant='contained' color='secondary' onClick={() => handlePublish(false)}>Retract Results</Button>
        </CardActions>
      </Card>
    </div>
  )
}
