import { Button, Card, CardActions, CardContent, CircularProgress, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { elevation } from '../shared/consants';
import { Check, Delete } from '@material-ui/icons';
import { deleteNotification, markAllAsSeen, markAsSeen } from './NotificationFunctions';
import { useDb } from '../../contexts/DatabaseContext';
import { Pagination } from '@material-ui/lab';
import moment from 'moment';

const Notifications = () => {

    const { GetAllHostNoti } = useDb()

    const [notifications, setNotifications] = useState([])
    const [loading, setLoading] = useState(false)
    const [filter, setFilter] = useState(null)
    const [update, setUpdate] = useState(0)
    const notis = GetAllHostNoti()

    const [itemsPerPage, setItemsPerPage] = useState(7)
    const [page, setPage] = useState(1) 
    

    useEffect(async() => {
        setLoading(true)
        setNotifications(notis)
        if (filter !== null) {
            let tempArray = []
            await notis.map(n => {
                if (!n.seen) {
                    tempArray.push(n)
                }
            })
            setNotifications(tempArray)
        } else {
            setNotifications(notis)
        }
        console.log("Filtering...")
        setLoading(false)
    }, [filter, update])

    console.log(notifications)
    
    
    return (
        <>
            {
                loading
                ? 
                <CircularProgress />
                :
                <>
                    <h2>Notifications</h2>
                    <Card elevation={elevation} style={{borderRadius: 10}}>
                        <CardContent>   
                            {
                            filter == null 
                            ? 
                            <Button id="1" className='pm-right' onClick={() => setFilter("unseen")}>Filter Unseen</Button> 
                            : 
                            <Button id='1' className='pm-right' onClick={() => setFilter(null)}>Unfilter</Button>}
                            <Table>
                                <TableHead>
                                    <TableCell>Timestamp</TableCell>
                                    <TableCell>Competition Name</TableCell>
                                    <TableCell>Username</TableCell>
                                    <TableCell>Message</TableCell>
                                    <TableCell>PmNumber</TableCell>
                                    <TableCell>Pool</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableHead>
                                <TableBody>
                                    {
                                        (notifications.length == 0 ? notis && notis : notifications)
                                        .slice((page -1) * itemsPerPage, page * itemsPerPage)
                                        .map(noti => {
                                            return(
                                                <TableRow key={noti.id}>
                                                    <TableCell>{moment(noti.timestamp.toDate()).format('DD/MM/YY h:mm')}</TableCell>
                                                    <TableCell>{noti.compName}</TableCell>
                                                    <TableCell>{noti.user.firstName} {noti.user.lastName}</TableCell>
                                                    <TableCell>{noti.message}</TableCell>
                                                    <TableCell>{noti.user.pmType} {noti.user.pmNumber}</TableCell>
                                                    <TableCell>{noti.booking.pool? "220" : noti.user.pmType == "NM" ? "150" : "110"}</TableCell>
                                                    {
                                                        noti.seen 
                                                        ?
                                                        <TableCell>
                                                            <IconButton disabled><Check /></IconButton>
                                                            <Tooltip title="Delete" placement='top'>
                                                                <IconButton onClick={() => {
                                                                    deleteNotification(noti)
                                                                    setUpdate(update +1)
                                                                }}><Delete /></IconButton>
                                                            </Tooltip>
                                                        </TableCell>
                                                        :
                                                        <TableCell>
                                                            <Button onClick={() => markAsSeen(noti)}>Mark As Seen</Button>
                                                            <Tooltip title="Delete" placement='top'>
                                                                <IconButton onClick={() => {
                                                                    deleteNotification(noti)
                                                                    setUpdate(update +1)
                                                                }}><Delete /></IconButton>
                                                            </Tooltip>
                                                        </TableCell>
                                                    }
                                                </TableRow>
                                            )
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </CardContent>
                        <CardActions>
                        <Pagination
                            count={Math.ceil((notifications.length == 0 ? notis.length : notifications.length) / itemsPerPage)}
                            page={page}
                            onChange={(e, val) => {
                            setPage(val)
                            }}
                            defaultPage={page}
                            color="primary"
                            size="large"
                            showFirstButton
                            showLastButton
                        /> 
                        <Button id='2' className='pm-right' onClick={() => markAllAsSeen(notifications.length == 0 ? notis && notis : notifications)}>Mark All Seen</Button>
                        </CardActions>
                    </Card>
                </>
            }
        </>
    );
}

export default Notifications;
