import { Button } from '@material-ui/core';
import { Add, ConfirmationNumber } from '@material-ui/icons';
import React from 'react';
import { useState } from 'react';
import AddPmNumbers from './AddPmNumbers';
import PmNumberList from './PmNumberList';

export default function PmNumbers() {

  const [open, setOpen] = useState(false)

  const handleClose = () => {
    setOpen(false)
  }

  return <div>
    <h1>Pm Numbers</h1>
    <Button className='pm-right' startIcon={<Add />} onClick={() => setOpen(true)} variant={'contained'} color={'primary'} >Add Number</Button>
    <br />
    <br />
    <br />
    <PmNumberList />
    <AddPmNumbers open={open} handleClose={handleClose} />
  </div>;
}
