import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from '@material-ui/core'
import Select from 'react-select'
import React, { useEffect, useState } from 'react'
import { selectFieldProps } from '../../shared/consants'
import { useParams } from 'react-router-dom'
import { useDb } from '../../../contexts/DatabaseContext'
import { CancelSharp, PersonAdd } from '@material-ui/icons'

export default function BookUser({ open, close }) {

  const { compId } = useParams()
  const { GetAnglers, getComp, bookUser } = useDb()
  const [pool, setPool] = useState(false) 
  const [teamName, setTeamName] = useState(null) 
  const [comp, setComp] = useState({}) 
  const [angler, setAngler] = useState({}) 
  
  useEffect( async () => {
    const c = await getComp(compId)
    setComp(c)
  }, [open])

  console.log(comp)
  
  const anglers = GetAnglers()
  const anglerOptions = anglers.map(a => ({
    label: `${a.pmType} ${a.pmNumber} ${a.firstName} ${a.lastName}`,
    value: a
  }))
  
  const handleSelect = (val) => {
    setAngler(val)
  }
  const handleChange = (val) => {
    setTeamName(val)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    await bookUser({
      pool: pool,
      teamName: teamName ? teamName : '',
      compId: compId,
      compRef: comp.compRef,
      uid: angler.uid,
      userRef: angler.userRef
    })
    .then(() => {
      setPool(false)
      setTeamName(null)
      setAngler({})
      close()
    })
  }

  return (
    <>
      <Dialog open={open} onClose={() => close()} maxWidth="sm" fullWidth>
        <form onSubmit={(e) => handleSubmit(e)}>
          <DialogTitle>Book Angler</DialogTitle>
          <DialogContent>
            <Typography variant='span'>Pool: </Typography>
            <Checkbox name='pool' onChange={(e, checked) => setPool(checked)} />
            {
              comp.type == 'Pairs' || comp.type == 'Teams'
              ?
              <TextField
              label='Team Name'
              style={{marginBottom: 10}}
              required 
              autoCapitalize='true'
              name='teamName'
              variant="outlined" 
              type='text'
              fullWidth 
              onChange={(e) => handleChange(e.target.value)}
              />
              :
              null
            }
            <Typography>Select Angler: </Typography>
            <Select
              options={anglerOptions}
              {...selectFieldProps}
              fullWidth
              variant="outlined" 
              onChange={(val) => handleSelect(val.value)}
            ></Select>
          </DialogContent>
          <DialogActions>
            <Button variant='contained' color='secondary' onClick={() => close()} startIcon={<CancelSharp />}>Cancel</Button>
            <Button variant='contained' color='primary' type='submit' startIcon={<PersonAdd />}>Add</Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  )
}
