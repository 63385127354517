import React from 'react'
import { useParams } from 'react-router-dom'
import { useDb } from '../../../contexts/DatabaseContext'

export default function TeamResults() {

  const { getTeamResults } = useDb()
  const { compId } = useParams()
  getTeamResults(compId)

  return (
    <div>
      
    </div>
  )
}
