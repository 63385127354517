import { Button, Dialog, DialogContent, TextField } from '@material-ui/core'
import DateFnsUtils from '@date-io/date-fns'
import { DatePicker, KeyboardTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import React from 'react'
import Select from 'react-select'
import { useState } from 'react'
import { compTypes } from '../shared/consants'
import { useEffect } from 'react'
import { useDb } from '../../contexts/DatabaseContext'

export default function EditComp({field, type, data, open, handleClose, compId, setUpdated, updated}) {

  const { updateCompData, GetHostsDropDown } = useDb()
  const [change, setChange] = useState(null)
  const hosts = GetHostsDropDown()
  console.log(change)

  useEffect(() => {
    setChange(data)
    if(type === 'date' || type === 'time') {
      setChange(data.toDate())
    }
  }, [field])

  const handleSave = async () => {
    console.log(field, change, compId)
    try {
      await updateCompData(field, change, compId)
      .then(() => {
        setUpdated(updated + 1)
        handleClose()
      })
    } catch (error) {
      console.log(error.message)
    }
  }

  return (
    <Dialog open={open} onClose={() => {
      handleClose()
      setChange(null)
      }} maxWidth="md" fullWidth>
      <DialogContent>
        {
          type === 'text'
          ?
          <TextField
            defaultValue={data}
            required 
            multiline={field === 'notes' ? true : false}
            rows={field === 'notes' ? 3 : 1}
            variant="outlined" 
            fullWidth 
            onChange={(e) => setChange(e.target.value)}
          />
          :
          type === 'number'
          ?
          <TextField
            defaultValue={data}
            required
            variant='outlined'
            fullWidth
            inputMode='numeric'
            onChange={(e) => setChange(parseInt(e.target.value))}
          />
          :
          type === 'drop-down'
          ?
          <Select
            options={field === 'type' ? compTypes : null}
            onChange={(val) => setChange(val.value)}
            placeholder={field === 'type' ? data : null}
            menuPortalTarget={document.body}
            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 })}}
          />
          :
          type === 'host-drop-down'
          ?
          <Select
            options={field === 'host' ? hosts : null}
            onChange={(val) => setChange(val.value)}
            placeholder={field === 'host' ? data : null}
            menuPortalTarget={document.body}
            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 })}}
          />
          :
          type === 'date'
          ?
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <>
              <DatePicker label="Competition Date" fullWidth inputVariant="outlined" format="dd/MM/yyyy" value={change ? change : data.toDate()} onChange={setChange} />
            </>
          </MuiPickersUtilsProvider>
          :
          type === 'time'
          ?
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardTimePicker
              ampm={false}
              fullWidth
              variant="inline"
              value={change ? change : data.toDate()}
              onChange={(t) => setChange(t)}
              inputVariant="outlined"
            />
          </MuiPickersUtilsProvider>
          :
          <>
            <center><h1>404</h1></center>
            <center><h4>Somthing went wrong</h4></center>
          </>
        }
        <br />
        <br />
        <Button
          onClick={handleSave}
          fullWidth
          variant='contained'
          color='primary'
        >Save</Button>
      </DialogContent>
    </Dialog>
  )
}
