import { Button, Card, CardContent, CircularProgress, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import React from 'react';
import Select from 'react-select';
import { pmTypes, selectFieldProps, years } from '../shared/consants';
import { useState } from 'react';
import { useDb } from '../../contexts/DatabaseContext';
import { CSVLink } from 'react-csv';
import { useEffect } from 'react';

export default function ExportRankings() {

    const [selectedYear, setSelectedYear] = useState()
    const [selectedType, setSelectedType] = useState()
    const [data, setData] = useState()
    const [selected, setSelected] = useState(false)
    const [dataGot, setDataGot] = useState(false)
    const [dataCSV, setDataCSV] = useState()
    const [loading, setLoading] = useState(true)
    const { getRangkingshttps, getRangkingshttps2022 } = useDb()

    const getData = async (year) => {
        setSelected(true)
        setLoading(true)
        if(year == '2022') {
            const d = await getRangkingshttps2022()
            const sortD = await d.sort((a, b) => {
                if (a.bmtCalc === b.bmtCalc) {
                  return b.winnings - a.winnings;
                } else {
                  return b.bmtCalc - a.bmtCalc;
                }
              })
              let tempArray =[]
              sortD.map(sd => {
                  if(sd.pmType == selectedType)
                  tempArray.push(sd)
              })
            setData(await tempArray)
            setDataCSV(await tempArray)
        } else if(year == '2023') {
            const d = await getRangkingshttps()
            const sortD = await d.sort((a, b) => {
                if (a.bmtCalc === b.bmtCalc) {
                  return b.winnings - a.winnings;
                } else {
                  return b.bmtCalc - a.bmtCalc;
                }
              })
              let tempArray =[]
              sortD.map(sd => {
                  if(sd.pmType == selectedType)
                  tempArray.push(sd)
              })
              setData(await tempArray)
              setDataCSV(await tempArray)
            }
            console.log(data)
            setDataGot(true)
            setLoading(false)
    }

    return (
        <div>
            <h1>Export Rankings</h1>
            <br />
            <Card>
                <CardContent>
                    <p>Select Year</p>
                    <Select 
                    onChange={e => setSelectedYear(e.value)}
                    {...selectFieldProps}
                    fullWidth
                    options={years}
                    variant="outlined" 
                    />
                    <p>Select Type</p>
                    <Select 
                    onChange={e => setSelectedType(e.value)}
                    {...selectFieldProps}
                    fullWidth
                    options={pmTypes}
                    variant="outlined" 
                    />
                    <b><i>After new type selected click on "Get Data" to refresh the data</i></b>
                    <br />
                    <br />
                    <Button
                        disabled={!selectedType && !selectedType} 
                        variant='contained'
                        color='primary'
                        onClick={() => getData(selectedYear)}
                    >Get Data</Button>
                    {
                        dataGot
                        ?
                        <CSVLink
                            data={dataCSV && dataCSV}
                            style={{ marginTop: 5, textDecoration: 'none'}}
                            filename={`Rankings ${selectedYear} ${selectedType}`}
                        >
                            <Button className='pm-right' variant="contained" style={{ backgroundColor: 'green', color: '#fff'}}> Export view to CSV</Button>
                        </CSVLink>
                        :
                        null
                    }
                    <br />
                    <br />
                    {
                        selected 
                        ?
                            loading
                            ?
                            <CircularProgress />
                            :
                            <Card elevation={3}>
                                <CardContent>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>PM No</TableCell>
                                            <TableCell>PM Type</TableCell>
                                            <TableCell>Full Name</TableCell>
                                            <TableCell>Sponsor</TableCell>
                                            <TableCell>Total Comps</TableCell>
                                            <TableCell>BMT</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            (data)
                                            .map((ang) => {
                                            return (
                                                <TableRow key={ang.id}>
                                                    <TableCell>{`${ang.pmNumber}`}</TableCell>
                                                    <TableCell>{`${ang.pmType}`}</TableCell>
                                                    <TableCell>{ang.display_name}</TableCell>
                                                    <TableCell>{<img style={{height: 40}} src={ang.sponsorLogo} alt="Sponsor Logo"/>}</TableCell>
                                                    <TableCell>{ang.count}</TableCell>
                                                    <TableCell>{ang.bmtCalc}</TableCell>
                                                </TableRow>
                                            )
                                            })
                                        }
                                    </TableBody>        
                                </Table>
                                </CardContent>
                            </Card>
                        
                        :
                        null
                    }
                </CardContent>
            </Card>
        </div>
    );
}
