import React, { useEffect, useState } from 'react';
import { useDb } from '../../contexts/DatabaseContext';
import { year } from '../shared/consants';
import { Card, CardContent, CircularProgress, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';

export default function HostTotalComps() {

    const { GetHosts, getTotalHostComps } = useDb()
    const hosts = GetHosts()
    const [hosttotals, sethosttotals] = useState()
    const [loading, setLoading] = useState(true)

    useEffect(async () => {
        const d = await hosts.map(async host => ({
            ...host,
            total: await getTotalHostComps(host.uid, year),
        }))

        const sortedData = await (await Promise.all(d)).sort((a, b) => b.total - a.total)
        sethosttotals(sortedData)
        setLoading(false)
    }, [hosts])



    return (
        <div>
            {
                loading
                ?
                <CircularProgress />
                :
                <Card elevation={5} style={{borderRadius: 10}}>
                <CardContent>
                <Table>
                    <TableHead>
                        <TableCell>First Name</TableCell>
                        <TableCell>Last Name</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Phone Number</TableCell>
                        <TableCell>Total</TableCell>
                    </TableHead>
                    <TableBody>
                    { 
                        hosttotals.map(u => {
                        return(
                            <TableRow key={u.uid}>
                                <TableCell>{u.firstName}</TableCell>
                                <TableCell>{u.lastName}</TableCell>
                                <TableCell>{u.email}</TableCell>
                                <TableCell>{u.phone_number ? u.phone_number : u.phoneNumber}</TableCell>
                                <TableCell>{u.total}</TableCell>
                            </TableRow>
                        )
                        })
                    }
                    </TableBody>
                </Table>
                </CardContent>
            </Card>
            }
        </div>
    );
}