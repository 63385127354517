import { faArrowAltCircleRight, faEnvelope, faEnvelopeOpen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, Button, Card, CardActions, CardContent, CardHeader } from '@material-ui/core';
import moment from 'moment';
import React, { useState } from 'react';
import { markAllAsSeen, markAsSeen } from './NotificationFunctions';
import { Notifications } from '@material-ui/icons';
import { useDb } from '../../contexts/DatabaseContext';

const NotificationPopup = () => {

    const { GetHostNoti } = useDb()

    const notifications = GetHostNoti()
    console.log(notifications)

    return (
        <div>
            <Card>
                <CardContent>
                    {
                        !notifications.length == 0 ? 
                        notifications.map(notif => (
                            <Card id={notif.ref.id} style={{marginBottom: "20px", width: "100%"}}>
                                <CardHeader
                                    avatar={
                                        <Avatar style={{borderColor: '#2788C8'}}>
                                            <Notifications color='primary' />
                                        </Avatar>}
                                    title={<span>{notif.compName}</span>}
                                    subheader={<div>
                                        {notif.message} - {notif.user.firstName} {notif.user.lastName}
                                        <br />
                                        {moment(notif.timestamp.toDate()).fromNow()}
                                    </div>}
                                    action={
                                            notif.seen?
                                            <Button variant='contained' color='primary'>Seen</Button>
                                            :
                                            <Button variant='contained' color='primary' onClick={() => markAsSeen(notif)}>Mark As Seen</Button>
                                    }
                                />
                            </Card>
                        ))
                        :
                        <Card style={{marginBottom: "20px", width: "100%"}}>
                                <CardHeader
                                    title={'No New Notifications'}
                                />
                            </Card>
                    }
                </CardContent>
                <CardActions>
                    <Button disabled={notifications.length == 0} variant='contained' color='primary' onClick={() => markAllAsSeen(notifications)}>Mark All As Seen</Button>
                </CardActions>
            </Card>
        </div>
    );
}

export default NotificationPopup;
