import { Button, CircularProgress } from '@material-ui/core'
import { ArrowBack } from '@material-ui/icons'
import moment from 'moment'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useAuth } from '../../../contexts/AuthContext'
import {useDb} from '../../../contexts/DatabaseContext'
import LogResults from './LogResults'
import ResultList from './ResultList'

export default function CompResults() {

  const history = useHistory()
  const { compId } = useParams()
  const { GetResults, getComp } = useDb()
  const { currentUser } = useAuth()
  const [open, setOpen] = useState(false)
  const results = GetResults(compId)
  const [loading, setLoading] = useState(true)
  const [comp, setComp] = useState({})

  useEffect( async () => {
    setLoading(true)
    const c = await getComp(compId)
    setComp(await c)
    setLoading(false)
  }, [compId])

  const handleClose = () => {
    setOpen(false)
  }

  console.log(results)

  return (
    <div>
      <h1>Competition Results</h1>
      {
        loading
        ?
        <CircularProgress />
        :
        <>
        <h2>{comp.name}: {comp.venue} - {moment(comp.date.toDate()).format("DD/MM/YYYY")}</h2>
          <Button startIcon={<ArrowBack />} variant='contained' color='primary' onClick={() => history.goBack()}>Back</Button>
          <Button disabled={comp.confirmedEntries !== comp.anglers.length} className='pm-right' variant='contained' color='primary' onClick={() => setOpen(true)} >Log Result</Button>
          {
            currentUser.role === 'Admin'
            ?
            <Button className='pm-right' disabled={results.length > 0} style={{marginRight: 5}} variant='contained' color='primary' onClick={() => history.push('import-results')} >Import Results</Button>
            :
            null
          }
          <br />
          <br />
          <ResultList results={results && results} />
          <LogResults open={open} handleClose={handleClose} compId={compId} />
        </>
      }
    </div>
  )
}
