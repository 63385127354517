import { BrowserRouter } from "react-router-dom";
import Wrapper from "./components/Wrapper";
import AuthProvider from "./contexts/AuthContext";

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Wrapper />
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
