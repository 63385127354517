import React from 'react';

const FloatingActionButton = ({ onClick }) => {
  return (
    <button className="floating-action-button" onClick={onClick}>
      Support
    </button>
  );
};

export default FloatingActionButton;
