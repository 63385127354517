import { Button } from '@material-ui/core'
import { ExitToApp } from '@material-ui/icons'
import React from 'react'
import { useAuth } from '../../contexts/AuthContext'

export default function NoAccess() {

  const { logOut } = useAuth()

  return (
    <div>
      <center>
        <br />
        <i><h1>No Access</h1></i>
        <Button style={{marginTop: 50}} onClick={() => logOut()} startIcon={<ExitToApp />} variant='contained' color='secondary'>Log Out</Button>
      </center>
    </div>
  )
}
