import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import Anglers from '../components/anglers/Anglers'
import EditAnglers from '../components/anglers/EditAnglers'
import Bookings from '../components/competitions/bookings/Bookings'
import Competitions from '../components/competitions/Competitions'
import CompetitionsDetails from '../components/competitions/CompetitionsDetails'
import CompResults from '../components/competitions/results/CompResults'
import ImportResults from '../components/competitions/results/ImportResults'
import PmNumbers from '../components/pm_numbers/PmNumbers'
import Rankings from '../components/rankings/Rankings'
import Sponsors from '../components/sponsors/Sponsors'
import Marshalls from '../components/competitions/marshall/Marshalls'
import ImportAnglers from '../components/anglers/ImportAnglers'
import ImportBookings from '../components/competitions/bookings/ImportBookings'
import Logs from '../components/logs/Logs'
import NoAccess from '../components/customer/NoAccess'
import { useAuth } from '../contexts/AuthContext'
import EditResults from '../components/competitions/results/EditResults'
import Teams from '../components/competitions/teams/Teams'
import TeamResults from '../components/competitions/teams/TeamResults'
import AdminFunctions from '../components/admin/admin_functions/AdminFunctions'
import AnglerDetails from '../components/anglers/AnglerDetails'
import Rankings2022 from '../components/rankings/Rankings2022'
import RankingsPuplic from '../public/RankingsPublic'
import RankingsPuplic2022 from '../public/RankingsPublic2022'
import ToNewLink from '../components/admin/ToNewLink'
import TaskView from '../components/supportTickets/TaskView'
import TicketDetail from '../components/supportTickets/TicketDetail'
import Notifications from '../components/notifications/Notifications'
import HostTotalComps from '../components/admin/HostTotalComps'
import ExportRankings from '../components/rankings/ExportRankings'
import ArchivedComps from '../components/competitions/ArchivedComps'
import Rankings2023 from '../components/rankings/Rankings2023'

export default function AdminRoutes() {

    const { currentUser } = useAuth()

    if (currentUser.role === 'Admin') {
        return(
            <div>
                <Route exact path="/" component={ToNewLink} />
                <Route exact path="/tickets" component={TaskView} />
                <Route exact path="/func" component={AdminFunctions} />
                <Route exact path="/competitions/:compId/import-results" component={ImportResults} />
                <Route exact path="/competitions/:compId/imp-bookings" component={ImportBookings} />
                <Route exact path="/anglers/:pager" component={Anglers} />
                <Route exact path="/anglers/import" component={ImportAnglers} />
                <Route exact path="/hosts" component={HostTotalComps} />
                <Route exact path="/anglers/:uid/edit" component={EditAnglers} />
                <Route exact path="/pm_numbers" component={PmNumbers} />
                <Route exact path="/sponsors" component={Sponsors} />
                <Route exact path="/export/rankings" component={ExportRankings} />
                <Route exact path="/logs" component={Logs} />
                <Route exact path="/notifications" component={Notifications} />
                <Route exact path="/" component={Competitions} />
                <Route exact path="/competitions/:pager" component={Competitions} />
                <Route exact path="/competitions/:pager/archived" component={ArchivedComps} />
                <Route exact path="/competitions/:compId/details" component={CompetitionsDetails} />
                <Route exact path="/competitions/:compId/teams" component={Teams} />
                <Route exact path="/competitions/:compId/teams/results" component={TeamResults} />
                <Route exact path="/competitions/:compId/results" component={CompResults} />
                <Route exact path="/competitions/:compId/result/:resId/edit" component={EditResults} />
                <Route exact path="/competitions/:compId/:compType/bookings" component={Bookings} />
                <Route exact path="/competitions/:compId/marshalls" component={Marshalls} />
                <Route exact path="/rankings" component={Rankings} />
                <Route exact path="/rankings-2023" component={Rankings2023} />
                <Route exact path="/rankings-2022" component={Rankings2022} />
                <Route exact path="/angler-details/:uid" component={AnglerDetails} />
                <Route exact path="/rankings/public" component={RankingsPuplic} />
                <Route exact path="/rankings/public/2022" component={RankingsPuplic2022} />
            </div>
        )
    } else if (currentUser.role === 'Host') {
        return (
            <div>
                <Route exact path="/" component={ToNewLink} />
                <Route exact path="/competitions/:pager" component={Competitions} />
                <Route exact path="/competitions/:pager/archived" component={ArchivedComps} />
                <Route exact path="/competitions/:compId/details" component={CompetitionsDetails} />
                <Route exact path="/competitions/:compId/results" component={CompResults} />
                    <Route exact path="/competitions/:compId/result/:resId/edit" component={EditResults} />
                <Route exact path="/competitions/:compId/:compType/bookings" component={Bookings} />
                <Route exact path="/competitions/:compId/marshalls" component={Marshalls} />
                <Route exact path="/rankings" component={Rankings} />
                <Route exact path="/rankings-2023" component={Rankings2023} />
                <Route exact path="/rankings-2022" component={Rankings2022} />
                <Route exact path="/notifications" component={Notifications} />
                <Route exact path="/angler-details/:uid" component={AnglerDetails} />
                <Route exact path="/rankings/public" component={RankingsPuplic} />
                <Route exact path="/rankings/public/2022" component={RankingsPuplic2022} />
            </div>
        )
    } else {
        return (
            <div>
                <Redirect from="*" to="/" />
                <Route exact path="/" component={NoAccess} />
            </div>
        )
    }
}
