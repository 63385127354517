import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import Forgot from '../components/auth/Forgot'
import SignIn from '../components/auth/SignIn'
import RankingsPuplic from '../public/RankingsPublic'
import RankingsPuplic2022 from '../public/RankingsPublic2022'
import RankingsPuplic2023 from '../public/RankingsPublic2023'

export default function PublicRoutes() {
    return (
        <>
            {  
                window.location.href.includes('competitions')
                ?
                <Redirect to={'/'}/>
                :
                null
            }
            {window.location.pathname === "/rankings/public" ? <Redirect to={'/rankings/public/'}/> : null}
            <Route exact path="/rankings/public" component={RankingsPuplic} />
            <Route exact path="/rankings/public/2022" component={RankingsPuplic2022} />
            <Route exact path="/rankings/public/2023" component={RankingsPuplic2023} />
            <Route exact path="/" component={SignIn} />
            <Route path="/forgot" component={Forgot} />
        </>
    )
}
