import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import NoAccess from '../components/customer/NoAccess'

export default function CustomerRoutes() {
    return (
        <>
            {/* {
                window.location.pathname === "/rankings/public" 
                ? 
                <Redirect to={'/rankings/public/'}/> 
                : 
                <>
                    <Redirect from="/signup" to="/" />
                    <Redirect from="/login" to="/" />
                    <Redirect from="*" to="/" />
                </>
            } */}
            <Route exact path="/" component={NoAccess} />
        </>
    )
}
