import {
  Button,
  Card,
  CardActions,
  CardContent,
  IconButton,
  Popper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import { Delete, Edit } from "@material-ui/icons";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useDb } from "../../contexts/DatabaseContext";
import { elevation } from "../shared/consants";
import EditSponsor from "./EditSponsor";

export default function SponsorList() {
  const history = useHistory();
  const { GetSponsors, deleteSponsor } = useDb();
  const sponsors = GetSponsors();
  const [selectedSponsor, setSelectedSponsor] = useState();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const openAnchor = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;

  const handleEdit = (s) => {
    setSelectedSponsor(s);
    setOpen(true);
  };

  const handleDelete = async (e) => {
    try {
      console.log(selectedSponsor);
      await deleteSponsor(selectedSponsor);
      setAnchorEl(anchorEl ? null : e.currentTarget);
    } catch (err) {
      console.log(err);
    }
  };

  const handleClick = (id, e) => {
    console.log(id);
    setSelectedSponsor(id);
    setAnchorEl(anchorEl ? null : e.currentTarget);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Card elevation={elevation} style={{ borderRadius: 10 }}>
        <CardContent>
          <Table>
            <TableHead>
              <TableCell>Name</TableCell>
              <TableCell>Logo</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableHead>
            <TableBody>
              {sponsors.map((s) => (
                <TableRow key={s.id}>
                  <TableCell>{s.name}</TableCell>
                  <TableCell>
                    <img width={100} src={s.logoUrl} alt="Logo" />
                  </TableCell>
                  <TableCell>
                    <Tooltip title="Edit">
                      <IconButton onClick={() => handleEdit(s)}>
                        <Edit />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    <Tooltip title="Unavailable">
                      <IconButton
                      // onClick={(e) => handleClick(s.sponsorId, e)}
                      >
                        <Delete />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
      {selectedSponsor ? (
        <EditSponsor
          open={open}
          handleClose={handleClose}
          sponsor={selectedSponsor}
        />
      ) : null}
      <Popper placement="left" id={id} open={openAnchor} anchorEl={anchorEl}>
        <Card elevation={5}>
          <CardContent>
            <center>
              <h2>Are You Sure</h2>
            </center>
            <p style={{ fontSize: 18 }}>
              <b>This Action Cannot be undone</b>
            </p>
          </CardContent>
          <CardActions>
            <Button
              variant="contained"
              color="primary"
              onClick={(e) => {
                handleDelete(e);
              }}
            >
              Yes, Delete
            </Button>
            <Button variant="outlined" color="secondary" onClick={handleClick}>
              Cancel
            </Button>
          </CardActions>
        </Card>
      </Popper>
    </div>
  );
}
