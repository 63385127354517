import React from 'react';
import { useHistory } from 'react-router-dom';

const ToNewLink = () => {
    const history = useHistory()
    history.push('/competitions/1')
    return (
        <div>
            
        </div>
    );
}

export default ToNewLink;
