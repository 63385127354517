import { Button, CircularProgress } from "@material-ui/core";
import { ArrowBack, CloudUpload, PersonAdd } from "@material-ui/icons";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../../contexts/AuthContext";
import { useDb } from "../../../contexts/DatabaseContext";
import BookingsList from "./BookingsList";
import BookUser from "./BookUser";

export default function Bookings() {
  const history = useHistory();
  const { compId } = useParams();
  const { getComp } = useDb();
  const { currentUser } = useAuth();
  const [comp, setComp] = useState({});
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(async () => {
    setLoading(true);
    const c = await getComp(compId);
    setComp(c);
    setLoading(false);
  }, [compId]);

  const handleClose = () => {
    setOpen(false);
  };

  console.log(comp);

  return (
    <div>
      <h1>Bookings</h1>
      {loading ? (
        <CircularProgress />
        ) : (
          <>
          <h2>{comp.name}: {comp.venue} - {moment(comp.date.toDate()).format("DD/MM/YYYY")}</h2>
          {currentUser.role == "Admin" ? (
            <Button
              className="pm-right"
              disabled={comp.anglers.length > 0}
              onClick={() =>
                history.push(`/competitions/${compId}/imp-bookings`)
              }
              style={{ marginLeft: 10 }}
              variant="contained"
              color="primary"
              startIcon={<CloudUpload />}
            >
              Import Bookings
            </Button>
          ) : null}
          {currentUser.role == "Admin" ? (
            <Button
              className="pm-right"
              onClick={() => setOpen(true)}
              variant="contained"
              color="primary"
              startIcon={<PersonAdd />}
            >
              Book
            </Button>
          ) : null}
          <Button
            startIcon={<ArrowBack />}
            variant="contained"
            color="primary"
            onClick={() => history.goBack()}
          >
            Back
          </Button>
          <br />
          <br />
          <BookingsList />
          <BookUser open={open} close={handleClose} />
        </>
      )}
    </div>
  );
}
