import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'

const config = {
    // Paste your Firebase config here

    // Dev:

    // apiKey: "AIzaSyAcW8AmPZ5m7oJBw4qutgfzS2JkUX0apmM",
    // authDomain: "pro-mania-dev.firebaseapp.com",
    // projectId: "pro-mania-dev",
    // storageBucket: "pro-mania-dev.appspot.com",
    // messagingSenderId: "329230622984",
    // appId: "1:329230622984:web:cd3652547bfa0436448cbd"

    // production
    
    apiKey: "AIzaSyDTsBjbncG-ywbEZc6IlGPWkrbVbZ93Wzc",
    authDomain: "promania-60d7d.firebaseapp.com",
    projectId: "promania-60d7d",
    storageBucket: "promania-60d7d.appspot.com",
    messagingSenderId: "355986868091",
    appId: "1:355986868091:web:c4c04b0e5a540cbceedfde"
}

const app = firebase.initializeApp(config);

export const auth = app.auth();
export const db = app.firestore();
export const storage = app.storage();
export const functions = app.functions;
export const FieldValue = firebase.firestore.FieldValue

export default app;