import { Button } from '@material-ui/core'
import { ArrowBack } from '@material-ui/icons'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import AddMarshall from './AddMarshall'
import MarshallList from './MarshallList'

export default function Marshalls() {

  const history = useHistory()
  const { compId } = useParams()
  const [open, setOpen] = useState(false) 
  const [update, setUpdate] = useState(0) 

  const handleClose = () => {
    setOpen(false)
    setUpdate(parseInt(Math.random() * 100))
  }

  return (
    <div>
      <h1>Marshalls</h1>
      <Button startIcon={<ArrowBack />} variant='contained' color='primary' onClick={() => history.goBack()}>Back</Button>
      <Button className='pm-right' variant='contained' color='primary' onClick={() => setOpen(true)} >Add Marshall</Button>
      <br />
      <br />
      <MarshallList update={update} />
      <AddMarshall open={open} close={handleClose} compId={compId} />
    </div>
  )
}
