import { createTheme, ThemeProvider } from '@material-ui/core'
import React from 'react'
import { useAuth } from '../contexts/AuthContext'
import { DatabaseProvider } from '../contexts/DatabaseContext'
import CustomerRoutes from '../routes/CustomerRoutes'
import PublicRoutes from '../routes/PublicRoutes'
import AdminPage from './admin/AdminPage'


// Set your theme colors and styles below. Details on Theming here: https://material-ui.com/customization/theming/#theming
const theme = createTheme({
    palette: {
        primary: {
            main: '#2788c8',
            contrastText: '#fff'
        },
        mainMenuText: '#fff'
    }
})

export default function Wrapper() {
    const { currentUser } = useAuth()
    console.log(currentUser)
    return (
        <ThemeProvider theme={theme}>
            <DatabaseProvider>
            {
            typeof currentUser !== typeof undefined
            ?
                currentUser.role === 'Admin' || currentUser.role === 'Host' 
                ? 
                <AdminPage /> 
                : 
                <CustomerRoutes /> 
            :
            <PublicRoutes />
            }
            </DatabaseProvider>
        </ThemeProvider>
    )
}
