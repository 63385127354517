export const markAsSeen = (noti) => {
    console.log(noti)
    noti.ref.update({
        seen: true
    })
}

export const deleteNotification = (noti) => {
    noti.ref.delete()
}

export const markAllAsSeen = (notis) => {
    notis.map(n => {
        n.ref.update({
            seen: true
        })
    })
}