import { Card, CardActions, CardContent, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { useDb } from '../../contexts/DatabaseContext'
import { Pagination } from '@material-ui/lab'
import { elevation } from '../shared/consants'

export default function LogList() {

  const { GetLogs } = useDb()
  const logsList = GetLogs()
  const [search, setSearch] = useState('')
  
  const [logs, setLogs] = useState(logsList && logsList)

  useEffect(() => {
    let tempArray = []
    logsList.map(ll => {
      if(
        ll.firstName.toLowerCase().includes(search.toLowerCase()) ||
        ll.lastName.toString().toLowerCase().includes(search.toLowerCase()) ||
        ll.email.toString().toLowerCase().includes(search.toLowerCase()) ||
        moment(ll.timestamp.toDate()).format('DD/MM/YY h:mm').includes(search.toLowerCase()) ||
        ll.event.toLowerCase().includes(search.toLowerCase())
      ) {
        setPage(1)
        tempArray.push(ll)
      }
    })
    setLogs(tempArray)
  }, [search])
  
  // Pagination
  const itemsPerPage = 10
  const [page, setPage] = useState(1)
  const [noOfPages] = useState(
    Math.ceil((search ? logs : logsList).length / itemsPerPage)
  )

  return (
    <div>
      <Card elevation={elevation} style={{borderRadius: 10}}>
        <CardContent>
          <TextField
            label='Search'
            type='text'
            variant="outlined" 
            fullWidth 
            onChange={(e) => setSearch(e.target.value)}
          />
          <Table>
            <TableHead>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Role</TableCell>
              <TableCell>Event</TableCell>
              <TableCell>Timestamp</TableCell>
            </TableHead>
            <TableBody>
              {
                (search ? logs : logsList)
                .slice((page -1) * itemsPerPage, page * itemsPerPage)
                .map(l => (
                  <TableRow>
                    <TableCell>{`${l.firstName} ${l.lastName}`}</TableCell>
                    <TableCell>{l.email}</TableCell>
                    <TableCell>{l.role}</TableCell>
                    <TableCell>{l.event}</TableCell>
                    <TableCell>{moment(l.timestamp.toDate()).format('DD/MM/YY h:mm')}</TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
          </Table>
        </CardContent>
        <CardActions>
          <Pagination
            count={Math.ceil((search ? logs : logsList).length / itemsPerPage)}
            page={page}
            onChange={(e, val) => setPage(val)}
            defaultPage={1}
            color="primary"
            size="large"
            showFirstButton
            showLastButton
          />  
        </CardActions>
      </Card>
    </div>
  )
}
